<template>
  <div class="GlobalConfiguration">
    <a-tabs type="card" v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="基础设置">
        <a-table
          :dataSource="data"
          :columns="columns"
          rowKey="id"
          :pagination="{
            hideOnSinglePage: true,
          }"
          id="qqqqqqqqqqqqq"
        >
          <template #operation="{ record }">
            <a-button
              type="primary"
              size="small"
              style="margin-right: 4px"
              @click.stop="handleEdit(record.id)"
              >编辑</a-button
            >
            <!-- <a-button type="primary" size="small" @click="listenRecord"
              >听录音</a-button
            > -->
          </template>
          <template #keyword="{ record }">
            {{ record.keyword?.length || 0 }}个
          </template>
          <template #answer="{ record }">
            {{ record.answer?.length || 0 }}种
          </template>
          <template #type="{ record }">
            {{ hadleType(record.type) }}
          </template>
        </a-table>
      </a-tab-pane>
      <!-- <a-tab-pane key="2" tab="是的是的">Content of Tab Pane 2</a-tab-pane>
      <a-tab-pane key="3" tab="多少多少">Content of Tab Pane 3</a-tab-pane> -->
    </a-tabs>
    <a-drawer
      title="编辑基础设置"
      placement="right"
      :closable="false"
      v-model:visible="visible"
      :width="470"
      class="know-drawer"
      @close="formRest"
    >
      <a-form :model="formState">
        <a-form-item label="语境" required>
          <a-input :disabled="true" v-model:value="formState.name"></a-input>
        </a-form-item>
        <a-form-item label="类型" required>
          <a-select placeholder="请输入" v-model:value="formState.type">
            <a-select-option value="key">关键词</a-select-option>
            <a-select-option value="calm">静音超时</a-select-option>
            <a-select-option value="unmatched">未匹配</a-select-option>
            <a-select-option value="error">错误</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="关键词" v-if="formState.type === 'key'">
          <a-tag
            style="margin-bottom: 10px"
            v-for="item in formState.keyword"
            :key="item.id"
            closable
            @close="deleteLog(item.id)"
            >{{ item.value }}</a-tag
          >
          <div>
            <a-input
              placeholder="请输入"
              @blur="handBlur"
              v-if="keywordVisible"
              v-model:value="inputVal"
              style="width: 200px"
            ></a-input>
            <a-button v-else type="dashed" size="small" @click="addKeyword"
              ><PlusOutlined /> 添加关键词</a-button
            >
            <div style="margin-top: 10px" v-if="formState.keyword.length !== 0">
              <span class="delete-all" @click="deleteKeyword"
                >删除全部关键词</span
              >
            </div>
          </div>
        </a-form-item>
        <!-- <a-form-item label="相似问题">
          <a-textarea style="height:100px" placeholder="请输入"></a-textarea>
        </a-form-item> -->
        <!-- <a-form-item label="对话变量赋值">
          <a-switch></a-switch>
        </a-form-item> -->
        <a-form-item label="回答方式">
          <a-radio-group v-model:value="formState.answer_switch">
            <a-radio-button value="1">单句回答</a-radio-button>
            <a-radio-button value="2">进入多轮会话</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <!-- <a-form-item label="切换方式">
          <a-radio-group v-model:value="formState.qiehuan">
            <a-radio-button value="1">顺序</a-radio-button>
            <a-radio-button value="2">系统时间</a-radio-button>
          </a-radio-group>
        </a-form-item> -->
        <div v-if="formState.answer_switch === '1'">
          <div
            class="box-item"
            v-for="domain in formState.answer"
            :key="domain.key"
          >
            <div style="text-align: right">
              <DeleteOutlined @click="removeDomain(domain)" />
            </div>
            <a-form-item label="回答内容" required>
              <a-textarea
                placeholder="请输入该问题的标准答案"
                v-model:value="domain.answer_content"
              ></a-textarea>
            </a-form-item>

            <!-- @change="answergoChange(domain.answer_go, domain.key)" -->
            <a-form-item label="回答后">
              <a-select style="width: 100%" v-model:value="domain.answer_go">
                <a-select-option value="0">挂机</a-select-option>
                <a-select-option value="1">等待用户答应</a-select-option>
                <a-select-option value="2">跳转到主动指定流程</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="选择流程" v-if="domain.answer_go == 2">
              <a-select
                style="width: 100%"
                v-model:value="domain.process_id"
                placeholder="请选择"
              >
                <!-- <a-select-option value="18">辅导费</a-select-option>
              <a-select-option value="19">分担分担</a-select-option> -->
                <a-select-option
                  v-for="item in flowlist"
                  :key="item.process_id"
                  :value="item.process_id"
                  >{{ item.process_name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </div>
          <div style="text-align: right; color: #1890ff">
            <span @click="addDomain" style="cursor: pointer"
              >+添加新的回答</span
            >
          </div>
          <a-form-item label="其它设置">
            <a-checkbox-group
              class="group"
              v-model:value="formState.checkboxgroup"
            >
              <div><a-checkbox value="A">加入黑名单</a-checkbox></div>
              <div style="display: flex; align-items: center">
                <a-checkbox value="B">转到人工坐席</a-checkbox>
                <a-tooltip placement="top">
                  <template #title>
                    <span>
                      当使用该话术外呼或接待时，触发到当前节点，会发送请求人机切换的通知到所选的人工坐席组中的坐席。坐席收到通知后可点击“开始监听”，监听AI与客户的通话，
                      可切入到通话中，直接与客户对话
                    </span>
                  </template>
                  <QuestionCircleOutlined />
                </a-tooltip>
              </div>
              <div class="qiehuan" v-if="formState.checkboxgroup.includes('B')">
                <a-form-item label="切换到">
                  <a-radio-group
                    style="margin-right: 15px"
                    v-model:value="formState.is_person.type"
                  >
                    <a-radio-button value="group">坐席组</a-radio-button>
                    <a-radio-button value="phone">电话号码</a-radio-button>
                  </a-radio-group>
                  <a-tooltip placement="top">
                    <template #title>
                      <span>
                        请设置需要接听的手机号或者固话，未设置外呼转接线路，无法切换到手机号/固话（请先至【通信管理】-【号码管理】中选择一条无主叫或固话线路设置为转接线路，并保证该线路有充足的话费）。
                      </span>
                    </template>
                    <QuestionCircleOutlined />
                  </a-tooltip>
                </a-form-item>
                <div v-if="formState.is_person.type == 'group'">
                  <a-form-item label="坐席组" required>
                    <a-select
                      style="width: 100%"
                      v-model:value="formState.is_person.group_id"
                      placeholder="请选择"
                    >
                      <a-select-option value="lucy">分担分担</a-select-option>
                      <a-select-option value="rtrt">发代付的饭</a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form-item label="切换后">
                    <a-radio-group
                      v-model:value="formState.is_person.group_tran"
                    >
                      <a-radio value="1">通知人工监听和切入通话</a-radio>
                      <a-tooltip placement="top">
                        <template #title>
                          <span>
                            AI继续与客户通话，并发送请求人机切换通知到人工坐席
                          </span>
                        </template>
                        <QuestionCircleOutlined />
                      </a-tooltip>
                      <div>
                        <a-radio value="2">等待人工接听</a-radio>
                        <a-tooltip placement="top">
                          <template #title>
                            <span>
                              AI停止与客户对话，播放转人工等待背景音，在人工坐席界面显示来电弹屏，等待人工坐席接听，即转人工。
                            </span>
                          </template>
                          <QuestionCircleOutlined />
                        </a-tooltip>
                      </div>
                    </a-radio-group>
                  </a-form-item>
                </div>
                <a-form-item label="电话号码" required v-else>
                  <a-input
                    v-model:value="formState.is_person.phone"
                    placeholder="请输入"
                  ></a-input>
                </a-form-item>
              </div>
              <div><a-checkbox value="C">发送短信</a-checkbox></div>
            </a-checkbox-group>
          </a-form-item>
        </div>
        <a-form-item v-else label="多轮会话" required>
          <a-select
            v-model:value="formState.process_mul_id"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in flowmulList"
              :key="item.process_mul_id"
              :value="item.process_mul_id"
              >{{ item.process_name }}</a-select-option
            >
            <!-- <a-select-option value="1">fdffd</a-select-option>
            <a-select-option value="2">rere</a-select-option> -->
          </a-select>
        </a-form-item>
      </a-form>
      <div class="drawer-buttom">
        <a-button style="margin-right: 8px" @click="formRest">取消</a-button>
        <a-button type="primary" @click="onOk">确定</a-button>
      </div>
    </a-drawer>
    <a-modal
      v-model:visible="visibleModal"
      title="话术录音"
      centered
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      class="recoed-model"
      :footer="null"
      @cancel="formStateRest"
    >
      <div class="big-box">
        <div class="box">
          <a-tooltip placement="top" v-if="formRecord.isPlay">
            <template #title>
              <span>播放录音</span>
            </template>
            <PlayCircleOutlined @click="handlePlay" />
          </a-tooltip>
          <a-tooltip placement="top" v-else>
            <template #title>
              <span>停止播放</span>
            </template>
            <PauseCircleOutlined />
          </a-tooltip>
          <a-input
            style="width: 270px"
            v-model:value="formRecord.value"
            :disabled="true"
          ></a-input>
          <!-- <UploadOutlined /> -->
          <a-upload :file-list="fileList" :before-upload="beforeUpload">
            <a-tooltip placement="top">
              <template #title>
                <span>上传录音</span>
              </template>
              <UploadOutlined />
            </a-tooltip>
          </a-upload>
          <a-tooltip placement="top">
            <template #title>
              <span>下载录音</span>
            </template>
            <a :href="formRecord.address" target="_blank">
              <VerticalAlignBottomOutlined
            /></a>
          </a-tooltip>
          <a-tooltip placement="top" v-if="formRecord.isLuying">
            <template #title>
              <span>重新录音</span>
            </template>
            <AudioOutlined @click="startRecord" />
          </a-tooltip>
          <a-tooltip placement="top" v-else>
            <template #title>
              <span>停止录音</span>
            </template>
            <PauseCircleOutlined @click="stopRecord" />
          </a-tooltip>
          <!-- <a-popconfirm title="确定删除吗?">
            <a-tooltip placement="top">
              <template #title>
                <span>删除</span>
              </template>
              <DeleteOutlined />
            </a-tooltip>
          </a-popconfirm> -->
        </div>
        <div style="margin: 15px">上传录音仅支持WAV格式的音频文件</div>
        <a-button type="primary" :disabled="disabled" @click="saveWav"
          >保存录音</a-button
        >
      </div>
      <audio id="audioplay" style="visibility: hidden"></audio>
    </a-modal>
    <a-drawer
      title="语境详情"
      placement="right"
      :closable="false"
      v-model:visible="visibleDetail"
      :width="520"
      class="detail"
    >
      <a-form :model="drawerState">
        <a-form-item label="语境">
          <span> {{ drawerState.name }}</span>
        </a-form-item>
        <a-form-item label="关键词">
          <div v-if="drawerState.keyword.length !== 0">
            <a-tag v-for="(item, index) in drawerState.keyword" :key="index">{{
              item
            }}</a-tag>
          </div>
          <div v-else>暂无关键词</div>
        </a-form-item>
        <div
          style="margin-top: 15px"
          v-for="(item, index) in drawerState.answer"
          :key="item.sga_id"
        >
          答案{{ index + 1 }}
          <a-form-item label="回答内容">
            <span style="margin-right: 6px"> {{ item.answer_content }}</span
            ><a-button @click="lisenRecord(item)" type="primary" size="small"
              >听录音</a-button
            >
          </a-form-item>
          <a-form-item label="回答后">
            <span> {{ item.next }}</span>
          </a-form-item>
        </div>
      </a-form>
    </a-drawer>
  </div>
</template>

<script>
import {
  getList,
  getEdit,
  postEdit,
  getFlowlist,
  getDownload,
  postUpload,
  getFlowmullist,
} from "../../service/globalconfig";
import {
  PlusOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  UploadOutlined,
  VerticalAlignBottomOutlined,
  AudioOutlined,
} from "@ant-design/icons-vue";
import { reactive, ref, toRefs, onMounted, watch, nextTick } from "vue";
import Recorder from "js-audio-recorder";
import { message } from "ant-design-vue";

// const dataSource = [
//   {
//     key: "1",
//     name: "胡彦斌",
//     age: 32,
//     address: "西湖区湖底公园1号",
//   },
//   {
//     key: "2",
//     name: "胡彦祖",
//     age: 42,
//     address: "西湖区湖底公园1号",
//   },
// ];
const columns = [
  {
    title: "名称",
    dataIndex: "name",
  },
  {
    title: "类型",
    dataIndex: "type",
    slots: {
      customRender: "type",
    },
  },
  {
    title: "关键词(个)",
    dataIndex: "keyword",
    slots: {
      customRender: "keyword",
    },
  },
  {
    title: "回复话术(个)",
    dataIndex: "answer",
    slots: {
      customRender: "answer",
    },
  },
  {
    title: "操作",
    dataIndex: "operation",
    fixed: "right",
    slots: {
      customRender: "operation",
    },
    width: "80px",
  },
];
export default {
  name: "GlobalConfiguration",
  components: {
    PlusOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    PlayCircleOutlined,
    UploadOutlined,
    VerticalAlignBottomOutlined,
    AudioOutlined,
    PauseCircleOutlined,
  },
  props: ["mainId"],
  setup(props) {
    // console.log(props);
    let recorder = new Recorder();
    const state = reactive({
      visible: false,
      keywordVisible: false,
      inputVal: null,
      visibleModal: false,
      disabled: true,
      visibleDetail: false,
      data: [],
      flowlist: [],
      flowmulList: [],
      //   id: undefined,
    });
    const formState = reactive({
      id: undefined,
      name: undefined,
      type: undefined,
      keyword: [],
      answer_switch: "1",
      //   qiehuan: "1",
      answer: [
        {
          answer_content: undefined,
          key: 1,
          answer_go: "1",
          process_id: undefined,
        },
      ],
      checkboxgroup: [], //加入黑名单，发送短信
      is_person: {
        type: "group",
        group_id: undefined,
        group_tran: "1",
        pnone: undefined,
      },
      process_mul_id: undefined, //多轮会话id
    });
    const formRest = () => {
      formState.id = undefined;
      formState.name = undefined;
      formState.type = undefined;
      formState.keyword = [];
      formState.checkboxgroup = [];
      formState.answer_switch = "1";
      formState.is_person = {
        type: "group",
        group_id: undefined,
        group_tran: "1",
        pnone: undefined,
      };
      formState.answer = [
        {
          answer_content: undefined,
          key: 1,
          answer_go: "1",
          process_id: undefined,
        },
      ];
      state.visible = false;
      formState.process_mul_id = undefined;
    };
    const drawerState = reactive({
      id: undefined,
      name: undefined,
      answer: [],
      keyword: [],
    });
    // const handleClick = () => {
    //   state.visible = true;
    // };
    onMounted(() => {
      init();
      getFlowlistFun();
      getFlowmullistFun();
    });
    watch(
      () => props.mainId,
      () => {
        init();
      }
    );
    const init = async () => {
      const res = await getList({
        access_token: sessionStorage.getItem("token"),
        speech_id: props.mainId,
      });
      console.log(res);
      state.data = res.data.data;
    };
    const onOk = async () => {
      const params = {
        id: formState.id,
        name: formState.name,
        type: formState.type,
        answer_switch: formState.answer_switch,
        keyword: "",
        is_black: formState.checkboxgroup.includes("A") ? 2 : 1,
        is_sms: formState.checkboxgroup.includes("C") ? 2 : 1,
        is_person: formState.checkboxgroup.includes("B")
          ? JSON.stringify(formState.is_person)
          : undefined,
        answer: JSON.stringify(formState.answer),
        speech_id: props.mainId,
        process_mul_id: formState.process_mul_id,
      };
      console.log(JSON.parse(params.answer));
      const question = JSON.parse(params.answer);
      console.log(question);
      for (let i = 0; i < question.length; i++) {
        if (
          !question[i].answer_content ||
          question[i].answer_content === undefined
        ) {
          message.warning("回答不能为空");
          return;
        } else if (!question[i].answer_content.trim()) {
          message.warning("回答不能为空");
          return;
        }
      }
      // question.forEach(item=>{
      //   if(!item.answer_content||item.answer_content === undefined){
      //     message.warning('回答不能为空')
      //     return
      //   }else if(!item.answer_content.trim()){
      //     message.warning('回答不能为空')
      //     return
      //   }
      // })
      if (params.type === "key") {
        formState.keyword.forEach(
          (item) => (params.keyword += item.value + ",")
        );
        if (params.keyword.charAt(params.keyword.length - 1) === ",") {
          params.keyword = params.keyword.substring(
            0,
            params.keyword.length - 1
          );
        }
      }
      const res = await postEdit({
        access_token: sessionStorage.getItem("token"),
        ...params,
      });
      console.log(res);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        formRest();
        init();
        state.visible = false;
      } else {
        message.error(res.data.msg);
      }
    };
    const addDomain = () => {
      formState.answer.push({
        answer_content: "",
        key: Date.now(),
        answer_go: "1",
        process_id: undefined,
      });
    };
    const removeDomain = (item) => {
      if (formState.answer.length === 1) {
        // message.warning("不能删除");
        return;
      }
      let index = formState.answer.indexOf(item);

      if (index !== -1) {
        formState.answer.splice(index, 1);
      }
    };
    const addKeyword = () => {
      state.keywordVisible = true;
    };
    const handBlur = () => {
      //   console.log(state.inputVal);
      if (state.inputVal && state.inputVal.trim()) {
        let str = state.inputVal.trim().split(",");
        str.forEach((item, index) =>
          formState.keyword.push({
            id: index + Date.now(),
            value: item,
          })
        );
        state.inputVal = undefined;
      }
      state.keywordVisible = false;
    };
    const deleteLog = (e) => {
      console.log(e);
      console.log(formState.keyword);
      let index = formState.keyword.findIndex((item) => item.id === e);
      formState.keyword.splice(index, 1);
    };
    nextTick(() => {
      setTimeout(() => {
        let tab = document.querySelector("#qqqqqqqqqqqqq tbody").children;
        tab.forEach((item, index) => {
          tab[index].addEventListener("click", async () => {
            drawerState.answer = [];
            state.visibleDetail = true;
            const res = await getEdit({
              access_token: sessionStorage.getItem("token"),
              id: state.data[index].id,
            });
            console.log(res);
            const { id, keyword, answer, name, wav } = res.data.data;
            drawerState.id = id;
            drawerState.name = name;
            drawerState.keyword = keyword.map((item) => item.kw_name);
            answer.forEach((item, index) => {
              let obj = {
                answer_content: item.answer_content,
                sga_id: item.sga_id,
                next: undefined,
                wav: wav[index],
              };
              if (item.answer_go === "2") {
                let index = state.flowlist.findIndex(
                  (i) => i.process_id === item.process_id
                );
                obj.next =
                  "跳转指定流程=>" + state.flowlist[index].process_name;
              } else {
                obj.next = item.answer_go === "0" ? "挂机" : "等待用户答应";
              }
              drawerState.answer.push(obj);
            });
          });
        });
      }, 1000);
    });
    const handleEdit = async (id) => {
      state.visible = true;
      //   state.id = id;
      getFlowlistFun();
      const res = await getEdit({
        access_token: sessionStorage.getItem("token"),
        id,
      });
      console.log(res);
      const { answer, keyword, type, name, setting, mul, answer_switch } =
        res.data.data;
      formState.name = name;
      formState.type = type;
      formState.answer_switch = answer_switch;
      formState.id = id;
      formState.keyword = keyword.map((item) => {
        return {
          id: item.sgk_id,
          value: item.kw_name,
        };
      });
      if (answer_switch === "2") {
        formState.process_mul_id = mul.process_mul_id;
      } else {
        formState.answer = answer.map((item, index) => {
          return {
            answer_content: item.answer_content,
            key: index + Date.now(),
            sga_id: item.sga_id,
            answer_go: item.answer_go,
            process_id: item.process_id,
          };
        });
        if (setting.length === 0) return;
        if (setting[0].set_value === "2") {
          formState.checkboxgroup.push("A");
        }
        if (setting[1].set_value === "2") {
          formState.checkboxgroup.push("C");
          return;
        }
        if (setting[2].set_value) {
          formState.checkboxgroup.push("B");
          formState.is_person = JSON.parse(setting[2].set_value);
        }
        // console.log(formState);
      }
      
    };
    const lisenRecord = async (item) => {
      console.log(item);
      state.visibleModal = true;
      formRecord.value = item.answer_content;
      formRecord.id = item.sga_id;
      console.log(item.wav);
      if (item.wav) {
        if (item.wav.upload_path) {
          formRecord.address =
            "/" + item.wav.upload_path;
          formRecord.time = item.wav.time || 5000;
        } else {
          const res = await getDownload({
            access_token: sessionStorage.getItem("token"),
            id: item.sga_id,
            type: "global",
            speech_id: props.mainId,
          });
          console.log(res);
          const { data } = res.data;
          formRecord.address = data.address;
          formRecord.time = Math.ceil(Number(data.time)) * 1000;
        }
      }

      // console.log(formRecord.time);
    };
    const formRecord = reactive({
      value: "大家好，我是渣渣辉",
      isPlay: true,
      isLuying: true,
      // type: undefined,
      id: undefined,
      address: undefined,
      time: undefined,
    });
    const formStateRest = () => {
      fileList.value = [];
      state.disabled = true;
    };
    const handlePlay = () => {
      formRecord.isPlay = false;
      let audio = document.querySelector("#audioplay");
      audio.src = formRecord.address;
      audio.play();
      setTimeout(() => {
        formRecord.isPlay = true;
      }, formRecord.time);
    };
    const beforeUpload = (file) => {
      fileList.value = [file];
      state.disabled = false;
      return false;
    };
    const fileList = ref([]);
    const startRecord = () => {
      formRecord.isLuying = false;
      //销毁录音
      // recorder.handleDestroy();
      fileList.value = [];
      state.disabled = true;
      recorder.start();
    };
    const stopRecord = () => {
      formRecord.isLuying = true;
      recorder.stop();
      fileList.value = [];
      fileList.value.push(recorder.getWAVBlob());
      state.disabled = false;
    };
    const saveWav = async () => {
      // console.log("保存录音");
      // console.log(fileList.value);
      // console.log(drawerState.answer);
      let formData = new FormData();
      formData.append("file", fileList.value[0]);
      formData.append("access_token", sessionStorage.getItem("token"));
      formData.append("speech_id", props.mainId);
      formData.append("sga_id", formRecord.id);
      const res = await postUpload(formData);
      // console.log(res);
      if (res.data.status === 100000) {
        message.success("上传成功");
        fileList.value = [];
        state.disabled = true;
        // state.visibleModal = false;
        formRecord.address = "/" + res.data.data.path;
        formRecord.time = Math.ceil(Number(res.data.data.time) * 1000);
        let index = drawerState.answer.findIndex(
          (item) => item.sga_id === formRecord.id
        );
        drawerState.answer[index].wav.upload_path = res.data.data.path;
        drawerState.answer[index].wav.time = formRecord.time;
      } else {
        message.error(res.data.msg);
      }
    };
    function hadleType(str) {
      switch (str) {
        case "key":
          return "关键词";
        case "calm":
          return "静音超时";
        case "unmatched":
          return "未匹配";
        case "error":
          return "错误";
      }
    }
    // const listenRecord = () => {
    //   state.visibleDetail = true;
    // };
    const getFlowlistFun = async () => {
      const res = await getFlowlist({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: props.mainId,
      });
      console.log(res);
      state.flowlist = res.data.data;
    };
    const getFlowmullistFun = async () => {
      const res = await getFlowmullist({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: props.mainId,
      });
      console.log(res);
      state.flowmulList = res.data.data;
    };
    const deleteKeyword = () => {
      formState.keyword = [];
    };
    return {
      activeKey: ref("1"),
      //   dataSource,
      columns,
      ...toRefs(state),
      onOk,
      formState,
      addDomain,
      removeDomain,
      addKeyword,
      handBlur,
      deleteLog,
      handleEdit,
      lisenRecord,
      formStateRest,
      handlePlay,
      beforeUpload,
      startRecord,
      stopRecord,
      saveWav,
      formRecord,
      fileList,
      hadleType,
      //   listenRecord,
      formRest,
      drawerState,
      deleteKeyword,
    };
  },
};
</script>
<style >
.know-drawer .ant-form-item-label {
  min-width: 100px;
}
.know-drawer .qiehuan .ant-form-item {
  margin-bottom: 10px;
}
.recoed-model .ant-upload-list-item {
  display: none;
}
.detail .ant-form-item-label {
  min-width: 100px;
  text-align: left;
}
.detail .ant-form-item {
  margin-bottom: 0px;
}
</style>
<style scoped>
.GlobalConfiguration {
  /* padding: 15px; */
  margin: 0 15px;
}
.drawer-buttom {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
.box-item {
  background-color: #f7f7f7;
  padding: 10px 10px 4px 0;
  margin-bottom: 15px;
}
.box-item .anticon-delete {
  cursor: pointer;
}
.box-item .anticon-delete:hover {
  color: #1890ff;
  cursor: pointer;
}
.know-drawer .ant-form {
  margin-bottom: 60px;
}
.big-box {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.box {
  display: flex;
  align-items: center;
}
.is-tts {
  color: #ccc;
}
.big-box .anticon {
  font-size: 20px;
  color: rgb(47, 130, 248);
  margin: 0 5px;
  cursor: pointer;
}
.anticon-pause-circle {
  color: red;
}
.delete-all {
  color: red;
  margin-top: 10px;
  cursor: pointer;
}
</style>