import http from "../axios/http"

//话术列表
export let getSpeechList=(params)=>{
    return http.get("/speech/speech_list",{params})
}
//话术添加
export let getSpeechAdd=(params)=>{
    return http.post("/speech/speech_add",params)
}

//话术删除
export let getSpeechDelete=(params)=>{
    return http.post("/speech/speech_del",params)
}

//话术复制
export let getSpeechCopy=(params)=>{
    return http.post("/speech/speech_copy",params)
}
