<template>
  <div class="tab2">
    <div class="tab2left">
      <div>
        <a-button
          type="dashed"
          style="width: 110px; color: #8c8c8c; margin-right: 6px"
          @click="techAdd"
        >
          <template #icon><PlusOutlined /></template>
          新建分组
        </a-button>
        <a-button
          type="dashed"
          style="width: 110px; color: #8c8c8c"
          @click="techEdit"
        >
          <template #icon><EditOutlined /></template>
          编辑分组
        </a-button>
        <a-tree
          :tree-data="list"
          @select="handleSelect"
          defaultExpandAll
          :replaceFields="{
            children: 'children',
            title: 'cate_name',
            key: 'cate_id',
          }"
        >
          <!-- <template #cate_name="{ title }">
          <span>{{ title }}</span><span>编辑</span>
          <a-typography-paragraph v-model:content="title" editable />
        </template> -->
        </a-tree>
      </div>

      <a-button
        type="dashed"
        style="width: 100%; color: #8c8c8c"
        @click="techDelete"
      >
        <template #icon><DeleteOutlined /></template>
        删除分组
      </a-button>
    </div>
    <div class="tab2content">
      <div class="tab2contenttop">
        <div>
          <!-- @search="onSearch" -->
          <a-input-search
            @search="onSearch"
            v-model:value="keyword"
            placeholder="搜索标题"
            enter-button
          />
        </div>
        <div>
          <a-dropdown>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item key="1"> 批量导入 </a-menu-item>
                <a-menu-item key="2"> 批量移动 </a-menu-item>
                <a-menu-item key="3"> 批量删除 </a-menu-item>
                <a-menu-item key="3"> 上传知识库 </a-menu-item>
              </a-menu>
            </template>
            <a-button>
              更多操作
              <DownOutlined />
            </a-button>
          </a-dropdown>
          <a-button type="primary" @click="handleAdd" style="margin-left: 10px"
            >添加</a-button
          >
        </div>
      </div>
      <!-- <div class="container">22222</div> -->
      <a-table
        :columns="columns"
        :data-source="data"
        :row-selection="{
          onChange: onSelectChange,
          selectedRowKeys: selectedRowKeys,
          getCheckboxProps: getCheckboxProps,
        }"
        rowKey="update_time"
      >
        <template #operation="{ record }">
          <!-- <a @click="onDelete(record.key)">Delete</a> -->
          <a-button
            type="primary"
            size="small"
            style="margin-right: 4px"
            @click="handleEdit(record.tech_id)"
            >编辑</a-button
          >
          <a-popconfirm
            v-if="data.length"
            title="确定删除吗?"
            @confirm="handleDelete(record.tech_id)"
          >
            <!-- <a>Delete</a> -->
            <a-button size="small">删除</a-button>
          </a-popconfirm>
        </template>
      </a-table>
    </div>
    <a-drawer
      :title="title"
      placement="right"
      :closable="false"
      v-model:visible="drawerVisible"
      :width="470"
      class="know-drawer"
      @close="formStateRest"
    >
      <a-form ref="formRef" :model="formState">
        <a-form-item label="类型">
          <a-radio-group v-model:value="formState.type">
            <a-radio-button value="1">一般问题</a-radio-button>
            <a-radio-button value="2">业务问题</a-radio-button>
            <br />
            <span style="color: #8c8c8c; font-size: 13px"
              >与业务场景无关的问题，如：您贵姓</span
            >
          </a-radio-group>
        </a-form-item>
        <a-form-item label="选择分组" required v-if="!tech_id">
          <a-tree-select
            v-model:value="formState.cate_id"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="list[0].children"
            placeholder="请选择"
            :replaceFields="{
              children: 'children',
              title: 'cate_name',
              key: 'cate_id',
              value: 'cate_id',
            }"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item label="标准问题" required>
          <a-input
            placeholder="请输入标准问题"
            v-model:value="formState.tech_name"
            :disabled="tech_id ? true : false"
          ></a-input>
        </a-form-item>
        <div style="height: 10px"></div>

        <a-form-item label="关键词" class="tags">
          <a-tag
            closable
            @close="log(index)"
            v-for="(item, index) in formState.tags"
            :key="item.key"
            style="margin-bottom: 4px"
            >{{ item.name }}</a-tag
          >
          <a-button
            size="small"
            type="dashed"
            style="color: #8c8c8c"
            @click="addNode"
          >
            <template #icon><PlusOutlined /></template>
            添加关键词
          </a-button>
        </a-form-item>
        <div class="guanjianci" v-if="formState.tags.length > 0">
          <span @click="handleCopy">复制全部关键词</span
          ><span @click="handleDel">删除全部关键词</span>
        </div>
        <a-form-item label="对话变量赋值">
          <a-switch v-model:checked="formState.checked"></a-switch>
        </a-form-item>
        <a-form-item label="回答方式" class="danjuhuida">
          <a-radio-group
            v-model:value="formState.tech_answer_method"
            @change="ssssChange"
          >
            <a-radio-button value="1">
              单句回答
              <a-tooltip placement="top">
                <template #title>
                  <span>
                    AI一句话回答客户问题，<br />如 客户：利息是多少？<br />
                    AI：利息5厘
                  </span>
                </template>
                <QuestionCircleOutlined />
              </a-tooltip>
            </a-radio-button>
            <a-radio-button value="2">
              进入对轮会话
              <a-tooltip placement="top">
                <template #title>
                  <span>
                    AI与客户需多轮对话结束该问题，<br />如
                    客户：有推荐房型吗？<br />
                    AI：请问您家几口人？ <br />客户：4口人 AI：推荐您4居室
                  </span>
                </template>
                <QuestionCircleOutlined />
              </a-tooltip>
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <div v-if="formState.tech_answer_method == 1">
          <a-form-item label="切换方式">
            <a-radio-group v-model:value="formState.tech_switch_method">
              <a-radio-button value="1">顺序</a-radio-button>
              <a-radio-button value="2">系统时间</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <div
            class="box-item"
            v-for="domain in formState.domains"
            :key="domain.key"
          >
            <div style="text-align: right">
              <DeleteOutlined @click="removeDomain(domain)" />
            </div>
            <a-form-item label="回答内容" required>
              <a-textarea
                placeholder="请输入该问题的标准答案"
                v-model:value="domain.answer_content"
              ></a-textarea>
            </a-form-item>
            <a-form-item label="回答后" v-if="formState.qiehuanhou != 2">
              <a-select
                style="width: 100%"
                v-model:value="domain.answer_go"
                @change="answergoChange(domain.answer_go, domain.key)"
              >
                <a-select-option value="0">挂机</a-select-option>
                <a-select-option value="1">等待用户答应</a-select-option>
                <a-select-option value="2">跳转到主动指定流程</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="选择流程" v-if="domain.answer_go == 2">
              <a-select
                style="width: 100%"
                v-model:value="domain.process_id"
                placeholder="请选择"
              >
                <!-- <a-select-option value="18">辅导费</a-select-option>
                <a-select-option value="19">分担分担</a-select-option> -->
                <a-select-option
                  v-for="item in processidData"
                  :key="item.process_id"
                  :value="item.process_id"
                  >{{ item.process_name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </div>
          <div style="text-align: right; color: #1890ff">
            <span @click="addDomain" style="cursor: pointer"
              >+添加新的回答</span
            >
          </div>
          <a-form-item label="其它设置">
            <a-checkbox-group
              class="group"
              v-model:value="formState.checkboxgroup"
            >
              <div><a-checkbox value="A">加入黑名单</a-checkbox></div>
              <div style="display: flex; align-items: center">
                <a-checkbox value="B">转到人工坐席</a-checkbox>
                <a-tooltip placement="top">
                  <template #title>
                    <span>
                      当使用该话术外呼或接待时，触发到当前节点，会发送请求人机切换的通知到所选的人工坐席组中的坐席。坐席收到通知后可点击“开始监听”，监听AI与客户的通话，
                      可切入到通话中，直接与客户对话
                    </span>
                  </template>
                  <QuestionCircleOutlined />
                </a-tooltip>
              </div>
              <div class="qiehuan" v-if="formState.checkboxgroup.includes('B')">
                <a-form-item label="切换到">
                  <a-radio-group
                    style="margin-right: 15px"
                    v-model:value="formState.radiogroup"
                  >
                    <a-radio-button value="1">坐席组</a-radio-button>
                    <a-radio-button value="2">电话号码</a-radio-button>
                  </a-radio-group>
                  <a-tooltip placement="top">
                    <template #title>
                      <span>
                        请设置需要接听的手机号或者固话，未设置外呼转接线路，无法切换到手机号/固话（请先至【通信管理】-【号码管理】中选择一条无主叫或固话线路设置为转接线路，并保证该线路有充足的话费）。
                      </span>
                    </template>
                    <QuestionCircleOutlined />
                  </a-tooltip>
                </a-form-item>
                <div v-if="formState.radiogroup == 1">
                  <a-form-item label="坐席组" required>
                    <a-select
                      style="width: 100%"
                      v-model:value="formState.is_person"
                      placeholder="请选择"
                    >
                      <a-select-option value="lucy">分担分担</a-select-option>
                      <a-select-option value="jack">gdfgf</a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form-item label="切换后">
                    <a-radio-group v-model:value="formState.qiehuanhou">
                      <a-radio value="1">通知人工监听和切入通话</a-radio>
                      <a-tooltip placement="top">
                        <template #title>
                          <span>
                            AI继续与客户通话，并发送请求人机切换通知到人工坐席
                          </span>
                        </template>
                        <QuestionCircleOutlined />
                      </a-tooltip>
                      <div>
                        <a-radio value="2">等待人工接听</a-radio>
                        <a-tooltip placement="top">
                          <template #title>
                            <span>
                              AI停止与客户对话，播放转人工等待背景音，在人工坐席界面显示来电弹屏，等待人工坐席接听，即转人工。
                            </span>
                          </template>
                          <QuestionCircleOutlined />
                        </a-tooltip>
                      </div>
                    </a-radio-group>
                  </a-form-item>
                </div>
                <a-form-item label="电话号码" required v-else>
                  <a-input
                    v-model:value="formState.phone"
                    placeholder="请输入"
                  ></a-input>
                </a-form-item>
              </div>
              <div><a-checkbox value="C">发送短信</a-checkbox></div>
            </a-checkbox-group>
          </a-form-item>
        </div>
        <a-form-item label="多轮会话" v-else required>
          <!-- <a-select placeholder="请选择回复该问题的多伦会话">
            <a-select-option value="A">A级(明确有意向)</a-select-option>
            <a-select-option value="B">B级(可能有意向)</a-select-option>
            <a-select-option value="C">C级(明确拒绝)</a-select-option>
          </a-select> -->
          <a-select
            style="width: 100%"
            v-model:value="formState.process_mul_id"
            placeholder="请选择回复该问题的多轮会话"
            :options="
              process_mul_list.map((item) => ({
                value: item.process_mul_id,
                label: item.process_name,
              }))
            "
          >
            <template #dropdownRender="{ menuNode: menu }">
              <v-nodes :vnodes="menu" />
              <!-- <a-divider style="margin: 4px 0" /> -->
              <div
                style="padding: 4px 8px; cursor: pointer; color: #1890ff"
                @mousedown="(e) => e.preventDefault()"
                @click="addItem"
              >
                <plus-outlined />
                添加多轮会话
              </div>
            </template>
          </a-select>
        </a-form-item>
        <a-form-item label="意向标签">
          <a-select
            style="width: 100%; margin-bottom: 15px"
            v-model:value="formState.yixiang"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in label_list"
              :key="item.lab_id"
              :value="item.lab_id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <div class="drawer-buttom">
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="onOk">确定</a-button>
      </div>
    </a-drawer>
    <a-modal
      v-model:visible="addNodeVisible"
      title="添加关键词"
      centered
      @ok="handleOk"
      :width="500"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
    >
      <a-form>
        <a-form-item label="关键词名称" required>
          <a-textarea
            style="height: 100px"
            placeholder="请输入"
            v-model:value="nodeName"
          />
        </a-form-item>
      </a-form>
      <span style="color: red; margin-left: 53px"
        >提示：添加多个关键词用英文，隔开</span
      >
    </a-modal>
    <a-modal
      v-model:visible="techAddVisible"
      title="添加分组"
      centered
      @ok="techAddOk"
      :width="600"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      class="tech-modal"
      @cancel="formStateTechRest"
    >
      <a-form :model="formStateTech" layout="inline">
        <a-form-item label="父级" required>
          <a-tree-select
            v-model:value="formStateTech.parent_id"
            style="width: 183px"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="list"
            placeholder="请选择"
            :replaceFields="{
              children: 'children',
              title: 'cate_name',
              key: 'cate_id',
              value: 'cate_id',
            }"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item label="分组名称" required>
          <a-input
            placeholder="请输入"
            v-model:value="formStateTech.cate_name"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="techEditVisible"
      title="编辑分组名称"
      centered
      @ok="techEditOk"
      :width="600"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      class="tech-modal"
      @cancel="formStateTechRest"
    >
      <a-form :model="formStateTechEdit" layout="inline">
        <a-form-item label="原名称" required>
          <a-tree-select
            v-model:value="formStateTechEdit.cate_id"
            style="width: 183px"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="list[0].children"
            placeholder="请选择"
            :replaceFields="{
              children: 'children',
              title: 'cate_name',
              key: 'cate_id',
              value: 'cate_id',
            }"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item label="新名称" required>
          <a-input
            placeholder="请输入"
            v-model:value="formStateTechEdit.cate_name"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="techDeleteVisible"
      title="删除分组"
      centered
      @ok="techDeleteOk"
      :width="500"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
    >
      <a-form>
        <a-form-item label="删除分组" required>
          <a-tree-select
            v-model:value="deleteCateid"
            style="width: 183px"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="list[0].children"
            placeholder="请选择"
            :replaceFields="{
              children: 'children',
              title: 'cate_name',
              key: 'cate_id',
              value: 'cate_id',
            }"
          >
          </a-tree-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="huihuaVisible"
      title="新增流程节点"
      centered
      @ok="handleOkhuihua"
      :width="400"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
    >
      <a-form>
        <a-form-item label="流程名称" required>
          <a-input placeholder="请输入" v-model:value="huihuaName" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="daoruVisible"
      title="批量导入-知识库问题"
      centered
      @ok="handleOkDaoru"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      class="daoru"
      @cancel="handleCancel"
    >
      <a-form>
        <a-form-item label="选择文件" required>
          <a-upload
            :file-list="fileList"
            :remove="handleRemove"
            :before-upload="beforeUpload"
          >
            <a-button type="primary" :disabled="fileList.length != 0">
              <upload-outlined></upload-outlined>
              上传文件
            </a-button>
          </a-upload>
        </a-form-item>
        <div style="margin: 15px 110px 15px 110px">
          <a href="/static/xls/logy.xlsx">
            <a-button>
              <VerticalAlignBottomOutlined />

              下载模板
            </a-button>
          </a>
        </div>
        <a-form-item label="重复问题处理" required>
          <a-radio-group v-model:value="daoruValue">
            <a-radio value="1">覆盖</a-radio>
            <a-radio value="2">跳过</a-radio>
          </a-radio-group>
        </a-form-item>
        <div style="margin-left: 110px; color: #666">
          导入问题将覆盖原有同名标准问题，原问题数据被删除
        </div>
      </a-form>
      <div>
        注意事项：
        <p>
          1、上传文件需符合模板要求，格式为.xlsx扩展名且不允许删除首行「批量导入必读」，否则导入将失败
        </p>

        <p>2、单次最多支持上传1000条</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  DownOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons-vue";
import { onMounted, reactive, toRefs, ref, watch } from "vue";
import {
  getTechList,
  getTechAdd,
  getTechEdit,
  getTechDelete,
  getLogyList,
  getLogyAdd,
  getLogyAdd2,
  postFlowAdd,
  getFlowList,
  postLogyDel,
  getLogyEdit,
  postLogyEdit,
  postLogyImport,
} from "../../service/knowledgebase.js";
import { message } from "ant-design-vue";
import { regTest } from "../../utils/regExp";
const columns = [
  {
    title: "标准问题",
    dataIndex: "tech_name",
  },
  {
    title: "关键词(个)",
    dataIndex: "key_number",
  },
  {
    title: "更新时间",
    dataIndex: "update_time",
  },
  {
    title: "操作",
    fixed: "right",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
];

// const data = [
//   {
//     key: 1,
//     name: "1",
//     age: "电话翻江倒海",
//     times: 0,
//     address: "A级(明确有意向)",
//   },
//   {
//     key: 2,
//     name: "2",
//     age: "地方活动回复地方的",
//     times: 0,
//     address: "B级(可能有意向)",
//   },
//   {
//     key: 3,
//     name: "2",
//     age: "gfgfgfg",
//     times: 0,
//     address: "B级(可能有意向)",
//   },
//   {
//     key: 4,
//     name: "2",
//     age: "gfgf",
//     times: 0,
//     address: "B级(可能有意向)",
//   },
//   {
//     key: 5,
//     name: "2",
//     age: "bvbv",
//     times: 0,
//     address: "B级(可能有意向)",
//   },
// ];
const treeData = [
  {
    title: "Node1",
    value: "0-0",
    key: "0-0",
    children: [
      {
        title: "Child Node1",
        value: "0-0-1",
        key: "0-0-1",
      },
      {
        title: "Child Node2",
        value: "0-0-2",
        key: "0-0-2",
      },
    ],
  },
  {
    title: "Node2",
    value: "0-1",
    key: "0-1",
  },
];
export default {
  name: "KnowledgeBase",
  components: {
    DownOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    EditOutlined,
    DeleteOutlined,
    UploadOutlined,
    VerticalAlignBottomOutlined,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    },
  },
  props: {
    mainId: {
      type: String,
    },
    classifyId: {
      type: String,
    },
    label_list: {
      type: Object,
    },
  },
  setup(props) {
    watch(
      () => props.mainId,
      () => {
        state.cate_id = 0;
        init();
      }
    );
    const state = reactive({
      selectedRowKeys: [],
      list: [
        {
          cate_name: "全部",
          cate_id: 0,
          children: undefined,
        },
      ],
      drawerVisible: false,
      addNodeVisible: false,
      techAddVisible: false,
      techEditVisible: false,
      techDeleteVisible: false,
      daoruVisible: false,
      huihuaVisible: false,
      nodeName: undefined,
      huihuaName: undefined,
      deleteCateid: undefined,
      keyword: undefined,
      data: [],
      processidData: [],
      // items: [
      //   { value: 13, label: "Jack" },
      //   { value: 12, label: "lucy" },
      // ],
      process_mul_list: [],
      tech_id: undefined,
      title: "添加问题",
      daoruValue: "1",
      label_list: [],
      // searchVal:undefined
    });
    const formState = reactive({
      type: "1", //类型
      tech_switch_method: "1", //顺序，系统时间
      tech_answer_method: "1", //单句回答，多轮会话
      // qiehuanfangshi: 1,
      tech_name: undefined,
      cate_id: 0,
      checked: false, //对话变量赋值
      tags: [], //关键词
      domains: [
        {
          answer_content: "",
          key: 122323343,
          answer_go: "1",
          process_id: undefined,
          as_id: undefined,
        },
      ],
      checkboxgroup: [], //加入黑名单，发送短信
      yixiang: undefined, //意向
      radiogroup: "1", //坐席组，电话号码
      qiehuanhou: "1", //切换后
      is_person: undefined, //坐席组id
      phone: undefined, //电话号码
      process_mul_id: undefined, //电话号码
    });
    const formStateRest = () => {
      formState.type = "1";
      formState.tech_switch_method = "1";
      formState.tech_answer_method = "1";
      // formState.qiehuanfangshi = 1;
      formState.tech_name = undefined;
      formState.cate_id = undefined;
      formState.checked = false;
      formState.tags = [];
      formState.domains = [
        {
          answer_content: "",
          key: 122323343,
          answer_go: "1",
          process_id: undefined,
        },
      ];
      formState.checkboxgroup = [];
      formState.yixiang = undefined;
      formState.radiogroup = "1";
      formState.qiehuanhou = "1";
      formState.phone = undefined;
      formState.process_mul_id = undefined;
      state.tech_id = undefined;
    };
    const formStateTech = reactive({
      parent_id: undefined,
      cate_name: undefined,
    });
    const formStateTechEdit = reactive({
      cate_id: undefined,
      cate_name: undefined,
    });
    const formStateTechRest = () => {
      formStateTech.parent_id = undefined;
      formStateTech.cate_name = undefined;
      formStateTechEdit.cate_id = undefined;
      formStateTechEdit.cate_name = undefined;
    };
    onMounted(() => {
      getLogyAdd2Fun();
      init();
    });
    const getLogyAdd2Fun = async () => {
      const res = await getLogyAdd2({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: props.mainId,
      });
      // console.log(res);
      state.process_mul_list = res.data.data.process_mul_list;
    };
    const getLogyListFun = async () => {
      const res = await getLogyList({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: props.mainId,
        // classify_id: props.classifyId,
        keyword: state.keyword,
        cate_id: state.cate_id,
      });
      console.log(res);
      state.data = res.data.data;
    };
    const init = async () => {
      const res = await getTechList({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: props.mainId,
      });
      console.log(res);
      state.list[0].children = res.data.data.group;
      console.log(state);
      getLogyListFun();
    };
    const handleSelect = (e) => {
      console.log(e);
      state.cate_id = e[0];
      getLogyListFun();
    };
    const onSelectChange = (selectedRowKeys) => {
      //   console.log("selectedRowKeys changed: ", selectedRowKeys);
      state.selectedRowKeys = selectedRowKeys;
    };
    const handleAdd = () => {
      state.drawerVisible = true;
      state.title = "添加问题";
      formState.cate_id = state.list[0].children[0].cate_id;
    };
    //确认添加
    const onClose = () => {
      formStateRest();
      state.drawerVisible = false;
    };
    const onOk = async () => {
      console.log(formState);
      if (!formState.tech_name) {
        message.warning("问题名称不能为空");
        return;
      } else if (!formState.tech_name.trim()) {
        message.warning("问题名称不能为空");
        return;
      }
      if(formState.tech_answer_method == 1){
        if(!formState.domains[0].answer_content.trim()){
        message.warning('回答内容不能为空')
        return
      }

      }
      if (formState.checkboxgroup.indexOf("B") !== -1) {
        if (formState.radiogroup == "1" && formState.is_person == undefined) {
          message.warning("坐席组不能为空");
          return;
        } else if (formState.radiogroup == "2") {
          // console.log(formState.phone,!formState.phone);
          if (!formState.phone) {
            message.warning("手机号码不能为空");
            return;
          } else if (!regTest(formState.phone)) {
            message.warning("手机号格式有误");
            return;
          }
        }
      }
      let isTrue = false;
      formState.domains.forEach((item) => {
        if (!item.answer_content) {
          isTrue = true;
        }
      });
      let str = "";
      formState.tags.forEach((item) => {
        str += item.name + ",";
      });
      str = str.substring(0, str.length - 1);
      const params = {
        talk_temp_id: props.mainId,
        cate_id: formState.cate_id,
        tech_name: formState.tech_name,
        tech_paramters: formState.checked ? "2" : "1",
        keyword: str,
        tech_answer_method: formState.tech_answer_method,
        label_id: formState.yixiang,
      };
      if (params.tech_answer_method == 1) {
        if (isTrue) {
          message.error("回答内容不能为空");
          return;
        }
        params.tech_switch_method = formState.tech_switch_method;
        if (state.tech_id) {
          formState.domains.forEach((item, index) => {
            item.key = index;
            // delete formState.domains[index].as_id;
          });
        }
        params.answer = JSON.stringify(formState.domains);
        if (formState.checkboxgroup.includes("A")) {
          params.is_black = 2;
        } else {
          params.is_black = 1;
        }
        if (formState.checkboxgroup.includes("B")) {
          // params.is_black = 2;
          if (formState.radiogroup == 1) {
            params.is_person = {};
            params.is_person.type = "group";
            params.is_person.group_id = formState.is_person;
            params.is_person.group_tran = formState.qiehuanhou;
            params.is_person = JSON.stringify(params.is_person);
          } else {
            params.is_person = {};
            params.is_person.type = "phone";
            params.is_person.phone = formState.phone;
            params.is_person = JSON.stringify(params.is_person);
          }
        }
        if (formState.checkboxgroup.includes("C")) {
          params.is_sms = 2;
        } else {
          params.is_sms = 1;
        }
      } else {
        params.process_mul_id = formState.process_mul_id;
        params.tech_switch_method = 3;
      }
      console.log(params);
      if (state.tech_id) {
        params.tech_id = state.tech_id;
        const res = await postLogyEdit({
          access_token: sessionStorage.getItem("token"),
          ...params,
        });
        if (res.data.status === 100000) {
          message.success(res.data.msg);
          state.drawerVisible = false;
          state.tech_id = undefined;
          formStateRest();
          init();
        } else {
          message.error(res.data.msg);
        }
      } else {
        const res = await getLogyAdd({
          access_token: sessionStorage.getItem("token"),
          ...params,
        });
        // console.log(res);
        if (res.data.status === 100000) {
          message.success(res.data.msg);
          state.drawerVisible = false;
          formStateRest();
          init();
        } else {
          message.error(res.data.msg);
        }
      }
    };
    //添加关键词，确认
    const addNode = () => {
      state.addNodeVisible = true;
    };
    const handleOk = () => {
      if (!state.nodeName.trim()) {
        message.error("不能为空");
      }
      if (state.nodeName.includes(",")) {
        state.nodeName.split(",").forEach((item) => {
          let obj = {
            key: Date.now(),
            name: item.trim(),
          };
          formState.tags.push(obj);
        });
        state.addNodeVisible = false;
        state.nodeName = undefined;
      } else {
        state.addNodeVisible = false;
        let obj = {
          key: Date.now(),
          name: state.nodeName.trim(),
        };
        formState.tags.push(obj);
        state.nodeName = undefined;
      }
      // let isHave = false;
      // formState.tags.forEach((item) => {
      //   if (item.name === state.nodeName.trim()) {
      //     isHave = true;
      //   }
      // });
      // if (!state.nodeName.trim()) {
      //   message.error("不能为空");
      // } else if (isHave) {
      //   message.error("该关键词已存在");
      // } else {
      //   state.addNodeVisible = false;
      //   let obj = {
      //     key: Date.now(),
      //     name: state.nodeName.trim(),
      //   };
      //   formState.tags.push(obj);
      //   state.nodeName = undefined;
      // }
    };
    const log = (index) => {
      // console.log(index);
      formState.tags.splice(index, 1);
      // console.log(formState.tags);
    };
    //添加分组
    const techAdd = () => {
      state.techAddVisible = true;
    };
    //确认添加分组
    const techAddOk = async () => {
      console.log(formState);
      console.log(state.list);
      if (formStateTech.parent_id === undefined) {
        message.error("父级不能为空");
        return;
      }else if(!formStateTech.cate_name||!formStateTech.cate_name.trim()){
        message.error("分组名称不能为空")
        return
      } else if (formStateTech.cate_name.length > 12) {
        message.error("分组名称不能大于12个字符");
        return;
      }
     if (formStateTech.parent_id === state.list[0].children[0].cate_id) {
        message.error("默认分组不能添加子分组");
        return;
      } 
      const techGroup = state.list[0].children
      for (let i = 0; i < techGroup.length; i++) {
        if(techGroup[i].cate_name === formStateTech.cate_name){
          message.warning('节点名称不能相同')
          return
        }
      }
      const res = await getTechAdd({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: props.mainId,
        ...formStateTech,
      });
      console.log(res)  ;
      if (res.data.status === 100000) {
        message.success("添加成功");
      } else {
        message.error("添加失败");
      }
      state.techAddVisible = false;
      formStateTechRest();
      init();
    };
    const techEdit = () => {
      state.techEditVisible = true;
    };
    const techEditOk = async () => {
      console.log(formStateTechEdit);
      if (!formStateTechEdit.cate_id) {
        message.error("不能为空");
        return;
      }else if(!formStateTechEdit.cate_name || !formStateTechEdit.cate_name.trim()){
        message.error("不能为空");
        return;
      }else if(formStateTechEdit.cate_name.length > 12){
        message.warning('分组名称不能大于12个字符')
        return
      }
      if (formStateTechEdit.cate_id === state.list[0].children[0].cate_id) {
        message.error("默认分组不能编辑");
        return;
      }
      const res = await getTechEdit({
        access_token: sessionStorage.getItem("token"),
        ...formStateTechEdit,
      });
      console.log(res);
      if (res.data.status === 100000) {
        message.success("添加成功");
      } else {
        message.error("添加失败");
      }
      state.techEditVisible = false;
      formStateTechRest();
      init();
    };
    const techDelete = () => {
      state.techDeleteVisible = true;
    };
    const techDeleteOk = async () => {
      if (!state.deleteCateid) {
        message.error("请选择要删除的分组");
        return;
      }
      if (state.deleteCateid === state.list[0].children[0].cate_id) {
        message.error("默认分组不能删除");
        return;
      }
      const res = await getTechDelete({
        access_token: sessionStorage.getItem("token"),
        cate_id: state.deleteCateid,
      });
      console.log(res);
      if (res.data.status === 100000) {
        message.success("删除成功");
      } else {
        message.error("删除失败");
      }
      state.techDeleteVisible = false;
      state.deleteCateid = undefined;
      init();
    };
    const removeDomain = (item) => {
      console.log(formState.domains);
      if (formState.domains.length === 1) {
        message.warning("不能删除");
        return;
      }
      let index = formState.domains.indexOf(item);

      if (index !== -1) {
        formState.domains.splice(index, 1);
      }
    };
    const addDomain = () => {
      formState.domains.push({
        answer_content: "",
        key: Date.now(),
        answer_go: "1",
        process_id: undefined,
      });
    };
    const addItem = () => {
      // items.value.push(`New item ${index++}`);
      state.huihuaVisible = true;
    };
    const handleOkhuihua = async () => {
      if (!state.huihuaName.trim()) {
        message.error("不能为空");
      } else {
        const res = await postFlowAdd({
          access_token: sessionStorage.getItem("token"),
          process_name: state.huihuaName.trim(),
          talk_temp_id: props.mainId,
        });
        console.log(res);
        if (res.data.status === 100000) {
          message.success(res.data.msg);
          state.huihuaVisible = false;
          // init();
          getLogyAdd2Fun();
        } else {
          message.error(res.data.msg);
        }
        // state.huihuaVisible = false;
        // state.items.push(state.huihuaName);
      }
    };
    //复制到剪切板
    const handleCopy = () => {
      console.log(formState.tags);
      let str = "";
      formState.tags.forEach(
        (item, index) =>
          (str = index === 0 ? str + item.name : str + "," + item.name)
      );
      // document.execCommand("2D-Position", "false", "true");
      let transfer = document.createElement("input");
      document.body.appendChild(transfer);
      // transfer.value = "这里是想要复制的内容";
      transfer.value = str;
      transfer.focus();
      transfer.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      transfer.blur();
      // console.log("复制成功");
      message.success("复制成功");
      document.body.removeChild(transfer);
    };
    const handleDel = () => {
      formState.tags = [];
    };
    const answergoChange = async (val, key) => {
      // console.log(val, key);
      if (val == 0 || val == 1) {
        let index = formState.domains.findIndex((item) => item.key == key);
        formState.domains[index].process_id = undefined;
      } else {
        const res = await getFlowList({
          access_token: sessionStorage.getItem("token"),
          talk_temp_id: props.mainId,
        });
        console.log(res);
        state.processidData = res.data.data;
      }
    };
    //执行删除
    const handleDelete = async (tech_id) => {
      console.log(tech_id);
      const res = await postLogyDel({
        access_token: sessionStorage.getItem("token"),
        tech_id,
      });
      console.log(res);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        getLogyListFun();
      } else {
        message.error(res.data.msg);
      }
    };
    //input搜索时间
    const onSearch = () => {
      getLogyListFun();
    };
    //编辑操作
    const handleEdit = async (tech_id) => {
      state.title = "编辑问题";
      formState.tags = [];
      state.tech_id = tech_id;
      state.drawerVisible = true;
      const res = await getLogyEdit({
        access_token: sessionStorage.getItem("token"),
        tech_id,
        talk_temp_id: props.mainId,
      });
      console.log(res);
      let data = res.data.data;
      state.label_list = data.label_list;
      state.processidData = data.process_list;
      state.process_mul_list = data.process_mul_list;
      formState.type = data.logy.info.type;
      // if (data.logy.answer.as_id) {
      //   formState.as_id = data.logy.answer.as_id;
      // }
      formState.tech_switch_method = data.logy.info.tech_switch_method;
      formState.tech_answer_method = data.logy.info.tech_answer_method;
      formState.tech_name = data.logy.info.tech_name;
      formState.cate_id = data.logy.info.cate_id;
      formState.process_mul_id = data.logy.ml_answer.process_mul_id;
      formState.yixiang = data.logy.info.label_id; //////
      formState.checked = data.logy.info.tech_paramters == 1 ? false : true;
      if (data.logy.keyword) {
        data.logy.keyword.split(",").forEach((item, index) => {
          let obj = {
            key: Date.now() + index,
            name: item,
          };
          formState.tags.push(obj);
        });
      }
      console.log(formState.tags);
      formState.domains = data.logy.answer;
      if (data.logy.setting.length === 0) return;
      if (data.logy.setting[0].set_value != 1) {
        formState.checkboxgroup.push("A");
      }
      if (data.logy.setting[1].set_value != 1) {
        formState.checkboxgroup.push("C");
      }
      if (
        data.logy.setting[2].set_value != 1 &&
        data.logy.setting[2].set_value != null
      ) {
        formState.checkboxgroup.push("B");
      }
      // switch(data.logy.info.label_id){
      //   case 0;
      //   formState.yixiang
      // }
      // formState.yixiang = data.logy.info.label_id;
      if (data.logy.setting[2].set_value) {
        let set_value = JSON.parse(data.logy.setting[2].set_value);
        console.log(set_value);
        // formState.radiogroup=data.logy.info.label_id
        if (set_value.type == "group") {
          formState.radiogroup = "1";
          formState.qiehuanhou = set_value.group_tran;
          formState.is_person = set_value.group_id;
        } else {
          formState.radiogroup = "2";
          formState.phone = set_value.phone;
        }
      }
    };
    //控制不能选中
    const getCheckboxProps = (record) => ({
      disabled: record.is_default === "1",
      // Column configuration not to be checked
      // name: record.name,
    });
    const handleMenuClick = (e) => {
      console.log(e);
      switch (e.key) {
        case "1":
          daoru();
          break;
      }
    };
    //执行导入操作
    const daoru = () => {
      console.log("导入");
      state.daoruVisible = true;
    };
    const handleRemove = (file) => {
      // const index = fileList.value.indexOf(file);
      // const newFileList = fileList.value.slice();
      // newFileList.splice(index, 1);
      // fileList.value = newFileList;
      console.log(file);
      fileList.value = [];
    };

    const beforeUpload = (file) => {
      fileList.value = [file];
      // fileList.value = [...fileList.value, file];
      // console.log(fileList.value, file);
      return false;
    };
    const fileList = ref([]);
    const handleOkDaoru = async () => {
      let formData = new FormData();
      formData.append("file", fileList.value[0]);
      formData.append("access_token", sessionStorage.getItem("token"));
      formData.append("repeat", state.daoruValue);
      formData.append("talk_temp_id", props.mainId);
      const res = await postLogyImport(
        // access_token: sessionStorage.getItem("token"),
        // file: formData,
        // repeat: state.daoruValue,
        // talk_temp_id: props.mainId,
        formData
      );
      console.log(res);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        state.daoruVisible = false;
        fileList.value = [];
        init();
      } else {
        message.error(res.data.msg);
      }
    };
    const handleCancel = () => {
      fileList.value = [];
    };
    const ssssChange = () => {
      console.log(formState.domains);
      if (formState.tech_answer_method == 1) {
        formState.domains = [
          {
            answer_content: "",
            key: 122323343,
            answer_go: "1",
            process_id: undefined,
          },
        ];
        formState.tech_switch_method = "1";
      }
    };
    return {
      columns,
      handleSelect,
      onSelectChange,
      ...toRefs(state),
      handleAdd,
      onOk,
      onClose,
      formState,
      treeData,
      addNode,
      handleOk,
      log,
      techAdd,
      formStateTech,
      techAddOk,
      formStateTechRest,
      techEdit,
      techEditOk,
      formStateTechEdit,
      techDelete,
      techDeleteOk,
      addDomain,
      removeDomain,
      addItem,
      handleOkhuihua,
      handleCopy,
      handleDel,
      answergoChange,
      formStateRest,
      handleDelete,
      onSearch,
      handleEdit,
      getCheckboxProps,
      handleMenuClick,
      daoru,
      fileList,
      // headers: {
      //   authorization: "authorization-text",
      // },
      handleOkDaoru,
      handleRemove,
      beforeUpload,
      handleCancel,
      ssssChange,
    };
  },
};
</script>
<style>
.know-drawer .ant-form-item-label {
  min-width: 100px;
}

.know-drawer .qiehuan .ant-form-item-label {
  min-width: 81px;
}
.know-drawer .qiehuan .ant-form-item {
  margin-bottom: 10px;
}
.know-drawer .ant-form-item:nth-last-child(1) {
  margin-top: 10px;
}
.daoru .ant-form-item {
  margin-bottom: 0px;
}
.daoru .ant-form-item-label {
  min-width: 110px;
}
</style>
<style scoped>
/* .KnowledgeBase {
  padding: 10px;
} */
.tab2 {
  padding: 0 10px 10px 10px;
  display: flex;
  width: 100%;
  height: 100%;
}

.tab2left {
  width: 240px;
  border-right: 1px solid #ccc;
  height: 100%;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tab2content {
  width: calc(100% - 240px);
  overflow: auto;
}
.tab2contenttop {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  width: 100%;
}
.tab2contenttop {
  margin-bottom: 15px;
}

.tab2 .ant-table-wrapper {
  margin-left: 10px;
}
.drawer-buttom {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
.group {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
}
.ant-checkbox-wrapper {
  margin-left: 0;
}
.danjuhuida .anticon-question-circle {
  color: #1890ff;
}
.tags {
  display: flex;
  flex-wrap: wrap;
}
.know-drawer .ant-form-item:nth-child(3) {
  margin-bottom: 0px;
}
.tech-modal .ant-form-item:nth-child(1) {
  margin-right: 30px;
}
.box-item {
  background-color: #f7f7f7;
  padding: 10px 10px 4px 0;
  margin-bottom: 15px;
}
.box-item .anticon-delete {
  cursor: pointer;
}
.box-item .anticon-delete:hover {
  color: #1890ff;
  cursor: pointer;
}

.guanjianci {
  margin-left: 100px;
  margin-bottom: 10px;
}
.guanjianci span {
  color: #1890ff;
  margin-right: 10px;
  cursor: pointer;
}
</style>