<template>
  <div class="Record">
    <div class="top">
      <div class="title">录音设置</div>
      <div class="btn-box">
        <a-form class="form">
          <div class="form-select">
            <a-form-item label="录音选择">
              <a-select
                v-model:value="obj.type"
                @change="typeChange"
                :disabled="xfList.length === 0 || aliList.length === 0"
              >
                <a-select-option value="xf">科大讯飞</a-select-option>
                <a-select-option value="ali"> 阿里云</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="录音方式">
              <a-select v-model:value="obj.man">
                <a-select-option value="1">真人录音</a-select-option>
                <a-select-option value="2">合成音</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="合成音" v-if="obj.type === 'ali'">
              <a-select v-model:value="obj.vcn" placeholder="请选择">
                <a-select-option
                  v-for="item in aliList"
                  :key="item.voice"
                  :value="item.voice"
                  >{{ item.name }} ({{ item.type }},{{
                    item.scene
                  }})</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="合成音" v-else>
              <a-select v-model:value="obj.vcn" placeholder="请选择">
                <a-select-option
                  v-for="item in xfList"
                  :key="item.voice"
                  :value="item.voice"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </div>
          <div class="form-select">
            <a-form-item label="音量">
              <a-slider v-model:value="obj.volume" />
            </a-form-item>
            <a-form-item label="语速">
              <a-slider v-model:value="obj.speed" />
            </a-form-item>
            <a-form-item label="语调" v-if="obj.type === 'ali'">
              <a-slider :min="-500" :max="500" v-model:value="obj.pitch" />
            </a-form-item>
          </div>
        </a-form>
        <div style="display: flex; flex-direction: column; margin-left: 40px">
          <a-textarea
            style="width: 300px; height: 90px; margin-bottom: 10px"
            placeholder="请输入内容试听"
            v-model:value="textareaVal"
          />
          <div>
            <a-button :disabled="!textareaVal" type="primary">试听</a-button>
          </div>
        </div>
      </div>
    </div>
    <div class="botton">
      <div class="btntop">
        <a-button type="primary" :disabled="tiDisable" @click="handleTijiao"
          >提交审核</a-button
        >
        <a-popconfirm title="确定清楚全部录音吗?" @confirm="handleClear">
          <a-button>删除全部录音</a-button>
        </a-popconfirm>
      </div>
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="流程节点图">
          <a-table
            :columns="columns"
            :data-source="data"
            rowKey="node_id"
            :pagination="{
              current: page,
              total: total,
            }"
            @change="handleChange"
          >
            <template #process_name="{ record }">
              <div>
                {{ record.process_name
                }}<span style="color: red">{{
                  record.info_title.includes("跳") ? "(跳)" : "(普)"
                }}</span>
              </div>
            </template>
            <template #is_real="{ record }">
              <div v-if="record.is_real == 1">未录音</div>
              <div v-else style="color: rgb(47, 130, 248)">已录音</div>
              <!-- {{ record.is_real == 1 ? "未录音" : "已录音" }} -->
            </template>
            <template #operation="{ record }">
              <a @click="handleEdit(record)" style="margin-right: 6px">录音</a>
              <!-- <a v-if="record.is_real == 2" @click="handlePlay">播放</a> -->
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="知识库">
          <a-table
            :columns="columns2"
            :data-source="data2"
            rowKey="as_id"
            :pagination="{
              current: page2,
              total: total2,
            }"
            @change="handleChange2"
          >
            <template #is_real="{ record }">
              <div v-if="record.is_real == 1">未录音</div>
              <div v-else style="color: rgb(47, 130, 248)">已录音</div>
            </template>
            <template #operation="{ record }">
              <a @click="handleEdit(record)" style="margin-right: 6px">录音</a>
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="3" tab="多轮会话">
          <a-table
            :columns="columns3"
            :data-source="data3"
            rowKey="node_mul_id"
            :pagination="{
              current: page3,
              total: total3,
            }"
            @change="handleChange3"
          >
            <template #process_name="{ record }">
              <div>
                {{ record.process_name
                }}<span style="color: red">{{
                  record.info_title.includes("跳") ? "(跳)" : "(普)"
                }}</span>
              </div>
            </template>
            <template #is_real="{ record }">
              <div v-if="record.is_real == 1">未录音</div>
              <div v-else style="color: rgb(47, 130, 248)">已录音</div>
            </template>
            <template #operation="{ record }">
              <a @click="handleEdit(record)" style="margin-right: 6px">录音</a>
            </template>
          </a-table>
        </a-tab-pane>
        <template #tabBarExtraContent>
          <a-checkbox v-model:checked="checked" @change="checkedChange"
            >待录音话术</a-checkbox
          >
        </template>
      </a-tabs>
    </div>
    <a-modal
      v-model:visible="visible"
      title="话术录音"
      centered
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      class="recoed-model"
      :footer="null"
      @cancel="formStateRest"
    >
      <div class="big-box">
        <div class="box">
          <a-tooltip placement="top" v-if="formState.isPlay">
            <template #title>
              <span>播放录音</span>
            </template>
            <PlayCircleOutlined @click="handlePlay" />
          </a-tooltip>
          <a-tooltip placement="top" v-else>
            <template #title>
              <span>停止播放</span>
            </template>
            <PauseCircleOutlined @click="handleStop" />
          </a-tooltip>
          <a-input
            style="width: 270px"
            v-model:value="formState.value"
            :disabled="true"
          ></a-input>
          <!-- <UploadOutlined /> -->
          <a-upload :file-list="fileList" :before-upload="beforeUpload">
            <a-tooltip placement="top">
              <template #title>
                <span>上传录音</span>
              </template>
              <UploadOutlined />
            </a-tooltip>
          </a-upload>
          <a-tooltip placement="top">
            <template #title>
              <span>下载录音</span>
            </template>
            <a :href="formState.address" target="_blank">
              <VerticalAlignBottomOutlined
            /></a>
          </a-tooltip>
          <a-tooltip placement="top" v-if="formState.isLuying">
            <template #title>
              <span>重新录音</span>
            </template>
            <AudioOutlined @click="startRecord" />
          </a-tooltip>
          <a-tooltip placement="top" v-else>
            <template #title>
              <span>停止录音</span>
            </template>
            <PauseCircleOutlined @click="stopRecord" />
          </a-tooltip>
          <a-popconfirm
            title="确定删除吗?"
            @confirm="handleDelete"
            v-if="formState.isTts"
          >
            <a-tooltip placement="top">
              <template #title>
                <span>删除</span>
              </template>
              <DeleteOutlined />
            </a-tooltip>
          </a-popconfirm>
          <a-tooltip placement="top" v-else>
            <template #title>
              <span>TTS不能删除</span>
            </template>
            <DeleteOutlined class="is-tts" />
          </a-tooltip>
        </div>
        <div style="margin: 15px">上传录音仅支持WAV格式(8000HZ,16bit,单声道)的音频文件</div>
        <a-button type="primary" :disabled="disabled" @click="saveWav"
          >保存录音</a-button
        >
        <!-- <a-button @click="handlePreview">试听</a-button> -->
        <!-- <a-button type="primary" @click="bofang">播放录音</a-button> -->
      </div>
      <audio id="audioplay" style="visibility: hidden"></audio>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, ref } from "@vue/reactivity";
import {
  PlayCircleOutlined,
  UploadOutlined,
  VerticalAlignBottomOutlined,
  AudioOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons-vue";
import {
  getSystemList,
  postTtsEdit,
  getNodeWav,
  postTts,
  postUploadwav,
  getDownload,
  postWavDel,
  postClearAll,
  getTechWav,
  getDownloads,
  postUploadTechwav,
  postTijiao,
  getMulWav,
  getDownloadss,
  postUploadDuolun,
  postTechDel,
  postMulDel,
} from "../../service/record.js";
import { onMounted, watch } from "@vue/runtime-core";
import { message } from "ant-design-vue";
import Recorder from "js-audio-recorder";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });
}
const columns = [
  {
    title: "流程节点",
    dataIndex: "process_name",
    key: "process_name",
    slots: {
      customRender: "process_name",
    },
  },
  {
    title: "话术内容",
    dataIndex: "info_talk_info",
    key: "info_talk_info",
    width: "75%",
  },
  {
    title: "真人录音",
    dataIndex: "is_real",
    key: "is_real",
    slots: {
      customRender: "is_real",
    },
  },
  {
    title: "操作",
    fixed: "right",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
];
const columns2 = [
  {
    title: "知识点标题",
    dataIndex: "tech_name",
    key: "tech_name",
  },
  {
    title: "话术内容",
    dataIndex: "answer_content",
    key: "answer_content",
    width: "75%",
  },
  {
    title: "真人录音",
    dataIndex: "is_real",
    key: "is_real",
    slots: {
      customRender: "is_real",
    },
  },
  {
    title: "操作",
    fixed: "right",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
];
const columns3 = [
  {
    title: "多轮会话节点",
    dataIndex: "process_name",
    key: "process_name",
    slots: {
      customRender: "process_name",
    },
  },
  {
    title: "话术内容",
    dataIndex: "info_talk_info",
    key: "info_talk_info",
    width: "75%",
  },
  {
    title: "真人录音",
    dataIndex: "is_real",
    key: "is_real",
    slots: {
      customRender: "is_real",
    },
  },
  {
    title: "操作",
    fixed: "right",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
];
export default {
  name: "Record",
  props: ["mainId", "count"],
  components: {
    PlayCircleOutlined,
    UploadOutlined,
    VerticalAlignBottomOutlined,
    AudioOutlined,
    DeleteOutlined,
    PauseCircleOutlined,
  },
  setup(props, { emit }) {
    // console.log(props.mainId);
    let recorder = new Recorder();
    const state = reactive({
      xfList: [],
      aliList: [],
      data: [],
      data2: [],
      data3: [],
      obj: {},
      num: 1,
      page: 1,
      page2: 1,
      page3: 1,
      checked: false,
      total: undefined,
      total2: undefined,
      total3: undefined,
      visible: false,
      disabled: true,
      is_real: "0",
      activeKey: "1",
      tiDisable: false,
      lastTime: 0,
      textareaVal: undefined,
      // pageSize: 20,
      // pageSize2: 20,
      // pageSize1: 20,
    });
    const formState = reactive({
      value: undefined,
      isPlay: true,
      isLuying: true,
      type: undefined,
      id: undefined,
      address: undefined,
      time: undefined,
      isTts: true,
    });
    watch(
      () => state.obj,
      async () => {
        state.num++;
        if (new Date().getTime() - state.lastTime < 1000) return;
        // console.log(state.lastTime, new Date().getTime());
        state.lastTime = new Date().getTime();
        if (state.num > 2 && state.obj.vcn) {
          const res = await postTtsEdit({
            access_token: sessionStorage.getItem("token"),
            ...state.obj,
            speech_id: props.mainId,
          });
          console.log(res);
          if (res.data.status === 100000) {
            message.success("操作成功");
          }
        }
      },
      {
        deep: true, // 深度监听的参数
      }
    );
    const handleEdit = async (record) => {
      console.log(record);
      state.visible = true;
      if (state.activeKey == "1") {
        formState.value = record.info_talk_info;
        formState.type = "flow";
        formState.id = record.node_id;
        if (record.is_real == "2") {
          formState.isTts = true;
          const res = await getDownload({
            access_token: sessionStorage.getItem("token"),
            node_id: record.node_id,
          });
          console.log(res);
          formState.address = `/${res.data.data.wav.upload_path}`;
          formState.time = res.data.data.wav.time;
        } else {
          formState.isTts = false;
          const params = {
            speech_id: props.mainId,
            type: formState.type,
            id: formState.id,
          };
          const res = await postTts({
            access_token: sessionStorage.getItem("token"),
            ...params,
          });
          console.log(res);
          formState.address = res.data.data.address;
          formState.time = res.data.data.time;
        }
      } else if (state.activeKey == "2") {
        console.log("我是知识库");
        formState.value = record.answer_content;
        formState.type = "tech";
        formState.id = record.as_id;
        if (record.is_real == "2") {
          formState.isTts = true;
          const res = await getDownloads({
            access_token: sessionStorage.getItem("token"),
            as_id: record.as_id,
          });
          console.log(res);
          formState.address = `/${res.data.data.wav.upload_path}`;
          formState.time = res.data.data.wav.stw_id;
        } else {
          formState.isTts = false;
          const params = {
            speech_id: props.mainId,
            type: formState.type,
            id: formState.id,
          };
          console.log(params);
          const res = await postTts({
            access_token: sessionStorage.getItem("token"),
            ...params,
          });
          console.log(res);
          formState.address = res.data.data.address;
          formState.time = res.data.data.time;
        }
      } else {
        formState.value = record.info_talk_info;
        formState.type = "mul";
        formState.id = record.node_mul_id;
        if (record.is_real == "2") {
          formState.isTts = true;
          const res = await getDownloadss({
            access_token: sessionStorage.getItem("token"),
            node_mul_id: record.node_mul_id,
          });
          console.log(res);
          formState.address = `/${res.data.data.wav.upload_path}`;
          formState.time = res.data.data.wav.stw_id;
        } else {
          formState.isTts = false;
          const params = {
            speech_id: props.mainId,
            type: formState.type,
            id: formState.id,
          };
          console.log(params);
          const res = await postTts({
            access_token: sessionStorage.getItem("token"),
            ...params,
          });
          console.log(res);
          formState.address = res.data.data.address;
          formState.time = res.data.data.time;
        }
      }
    };
    watch(
      () => props.mainId,
      () => {
        init();
      }
    );
    watch(
      () => props.count,
      () => {
        // init();
        getNodeWavFun();
        getTechWavFun();
        getMulWavFun();
      }
    );
    onMounted(() => {
      // console.log(11111111)
      init();
    });
    const typeChange = (e) => {
      console.log(e);
      state.obj.vcn = undefined;
    };
    const init = async () => {
      const res = await getSystemList({
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      // let obj = res.data.data.vcn;
      state.obj = res.data.data.tts;
      state.xfList = res.data.data.xf.vcn;
      state.aliList = res.data.data.ali.vcn;
      // for (let i in obj) {
      //   state.list.push({
      //     key: i,
      //     value: obj[i],
      //   }); //属性
      // }
      getNodeWavFun();
      getTechWavFun();
      getMulWavFun();
    };
    const handleChange = (e) => {
      // console.log(e.current);
      state.page = e.current;
      getNodeWavFun();
    };
    const handleChange2 = (e) => {
      // console.log(e.current);
      state.page2 = e.current;
      getTechWavFun();
    };
    const handleChange3 = (e) => {
      // console.log(e.current);
      state.page3 = e.current;
      getMulWavFun();
    };
    const getNodeWavFun = async () => {
      const res = await getNodeWav({
        access_token: sessionStorage.getItem("token"),
        page: state.page,
        speech_id: props.mainId,
        is_real: state.is_real,
      });
      console.log(res);
      // res.data.data.list.forEach((item) => delete item.children);
      state.data = res.data.data.list;
      state.total = res.data.data.total;
    };
    const getTechWavFun = async () => {
      const res = await getTechWav({
        access_token: sessionStorage.getItem("token"),
        page: state.page2,
        speech_id: props.mainId,
        is_real: state.is_real,
      });
      console.log(res);
      let arr = res.data.data.tech_list;
      res.data.data.list.forEach((item) => {
        let obj = arr.find((item2) => item2.tech_id == item.tech_id);
        item.tech_name = obj.tech_name;
      });
      state.data2 = res.data.data.list;
      console.log(state.data2);
      state.total2 = res.data.data.total;
    };
    const getMulWavFun = async () => {
      const res = await getMulWav({
        access_token: sessionStorage.getItem("token"),
        page: state.page3,
        speech_id: props.mainId,
        is_real: state.is_real,
      });
      console.log(res);
      // res.data.data.list.forEach((item) => delete item.children);
      state.data3 = res.data.data.list;
      state.total3 = res.data.data.total;
    };
    let timer;
    const handlePlay = async () => {
      let audio = document.querySelector("#audioplay");
      formState.isPlay = false;
      // const params = {
      //   speech_id: props.mainId,
      //   type: formState.type,
      //   id: formState.id,
      // };
      // const res = await postTts({
      //   access_token: sessionStorage.getItem("token"),
      //   ...params,
      // });
      // console.log(res);
      audio.src = formState.address;
      timer = setTimeout(() => {
        formState.isPlay = true;
      }, Math.ceil(Number(formState.time)) * 1000);
      audio.play();
    };
    const handleStop = () => {
      let audio = document.querySelector("#audioplay");
      audio.pause();
      clearTimeout(timer)
      formState.isPlay = true;
      // console.log(2222222);
    };
    // const postTtsFun = async (params) => {
    //   const res = await postTts({
    //     access_token: sessionStorage.getItem("token"),
    //     ...params,
    //   });
    //   console.log(res);
    //   return res.data.data;
    // };
    const beforeUpload = (file) => {
      fileList.value = [file];
      console.log(fileList.value);
      state.disabled = false;
      return false;
    };
    const handlePreview = async () => {
      let res = await getBase64(fileList.value[0]);
      // console.log(res);
      formState.address = res;
    };
    const fileList = ref([]);
    watch(
      () => fileList.value[0],
      () => {
        fileList.value[0] && handlePreview();
      }
    );
    const handleDelete = async () => {
      // console.log("我要删除");
      const params = {
        access_token: sessionStorage.getItem("token"),
      };
      if (state.activeKey === "1") {
        params.node_id = formState.id;
        const res = await postWavDel({
          ...params,
        });
        console.log(res);
        if (res.data.status === 100000) {
          message.success("删除成功");
          getNodeWavFun();
          state.visible = false;
        } else {
          message.error(res.data.msg);
        }
      } else if (state.activeKey === "2") {
        params.as_id = formState.id;
        const res = await postTechDel({
          ...params,
        });
        console.log(res);
        if (res.data.status === 100000) {
          message.success("删除成功");
          getTechWavFun();
          state.visible = false;
        } else {
          message.error(res.data.msg);
        }
      } else {
        params.node_mul_id = formState.id;
        const res = await postMulDel({
          ...params,
        });
        console.log(res);
        if (res.data.status === 100000) {
          message.success("删除成功");

          getMulWavFun();
          state.visible = false;
        } else {
          message.error(res.data.msg);
        }
      }
    };
    // const handleOk = () => {
    //   console.log(fileList.value);
    // };
    const saveWav = async () => {
      let formData = new FormData();
      formData.append("file", fileList.value[0]);
      formData.append("access_token", sessionStorage.getItem("token"));
      formData.append("speech_id", props.mainId);
      if (state.activeKey == "1") {
        formData.append("node_id", formState.id);
        const res = await postUploadwav(formData);
        console.log(res);
        if (res.data.status === 100000) {
          message.success("上传成功");
          fileList.value = [];
          state.disabled = true;
          state.visible = false;
          getNodeWavFun();
        } else {
          message.error(res.data.msg);
        }
      } else if (state.activeKey == "2") {
        formData.append("as_id", formState.id);
        const res = await postUploadTechwav(formData);
        console.log(res);
        if (res.data.status === 100000) {
          message.success("上传成功");
          fileList.value = [];
          state.disabled = true;
          state.visible = false;
          getTechWavFun();
        } else {
          message.error(res.data.msg);
        }
      } else {
        formData.append("node_mul_id", formState.id);
        const res = await postUploadDuolun(formData);
        console.log(res);
        if (res.data.status === 100000) {
          message.success("上传成功");
          fileList.value = [];
          state.disabled = true;
          state.visible = false;
          getMulWavFun();
        } else {
          message.error(res.data.msg);
        }
      }
    };
    const formStateRest = () => {
      fileList.value = [];
      state.disabled = true;
    };
    const startRecord = () => {
      formState.isLuying = false;
      //销毁录音
      // recorder.handleDestroy();
      fileList.value = [];
      state.disabled = true;
      recorder.start();
    };
    const stopRecord = () => {
      formState.isLuying = true;
      recorder.stop();
      fileList.value = [];
      fileList.value.push(recorder.getWAVBlob());
      // console.log(fileList.value);
      state.disabled = false;
      // fileList.value = [recorder.getWAVBlob()];
    };
    // const bofang = () => {
    //   console.log(recorder);
    //   console.log(recorder.getWAVBlob());
    //   recorder.downloadWAV();
    //   recorder.play();
    // };
    const checkedChange = () => {
      console.log(state.checked);
      state.checked ? (state.is_real = "1") : (state.is_real = "0");
      getNodeWavFun();
      getTechWavFun();
    };
    const handleClear = async () => {
      const res = await postClearAll({
        access_token: sessionStorage.getItem("token"),
        speech_id: props.mainId,
        type: state.activeKey == "1" ? "flow" : "tech",
      });
      console.log(res);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        state.activeKey == "1" ? getNodeWavFun() : getTechWavFun();
      } else {
        message.error(res.data.msg);
      }
    };
    const handleTijiao = async () => {
      state.tiDisable = true;
      const res = await postTijiao({
        access_token: sessionStorage.getItem("token"),
        speech_id: props.mainId,
      });
      if (res.data.status === 100000) {
        message.success("提交成功");
        setTimeout(() => {
          state.tiDisable = false;
        }, 10000);
        emit("changeActive", 111);
      } else {
        message.error(res.data.msg);
      }
    };
    return {
      ...toRefs(state),
      columns,
      columns2,
      columns3,
      handleEdit,
      handleChange,
      formState,
      handlePlay,
      handleStop,
      handleDelete,
      beforeUpload,
      saveWav,
      fileList,
      formStateRest,
      stopRecord,
      startRecord,
      checkedChange,
      handleClear,
      handleChange2,
      handleTijiao,
      typeChange,
      handleChange3,
      handlePreview,
    };
  },
};
</script>
<style>
.Record .ant-form-item .ant-form-item-label {
  min-width: 70px;
}
.Record .ant-tabs-bar {
  margin-bottom: 10px;
}
/* .recoed-model .ant-popover {
  z-index: 9999;
} */
.recoed-model .ant-upload-list-item {
  display: none;
}
.form .ant-form-item-control {
  height: 32px !important;
}
</style>
<style  scoped>
.Record {
  padding: 0 10px 10px 10px;
  width: 100%;
  height: 100%;
  overflow: auto;
}
/* .Record::-webkit-scrollbar {
  display: none;
} */
.top {
  border-bottom: 1px solid #ccc;
  padding: 10px 10px 20px 10px;
  margin-bottom: 10px;
}
.btn-box {
  display: flex;
}
.title {
  font-size: 17px;
  margin-bottom: 10px;
}
.form {
  /* width: 310px; */
  margin-left: 20px;
  display: flex;
  /* flex-wrap: wrap; */
}
/* .form .ant-form-item {
  margin-right: 50px;
} */
.form .ant-select {
  width: 200px;
}
.form-select {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.form-select .ant-slider {
  width: 200px;
}

/* .Record .ant-form-item {
  margin-bottom: 0;
} */
/* .Record .ant-form-item:nth-child(1) {
  margin-bottom: 15px;
} */
.btntop {
  /* text-align: right; */
  display: flex;
  justify-content: space-between;
}
.anticon {
  font-size: 20px;
  color: rgb(47, 130, 248);
  margin: 0 5px;
  cursor: pointer;
}
.anticon-pause-circle {
  color: red;
}
.big-box {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.box {
  display: flex;
  align-items: center;
}
.is-tts {
  color: #ccc;
}
</style>