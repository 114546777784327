<template>
  <div class="tab1">
    <div class="tab1left">
      <a-button type="primary" @click="handleAdd" style="width: 100%">
        <template #icon><PlusOutlined /></template>
        添加节点
      </a-button>
      <draggable
        v-model="tab1leftList"
        item-key="process_id"
        @change="dragChange"
        chosen-class="chosen"
        force-fallback="true"
        animation="300"
      >
        <template #item="{ element }">
          <div
            class="tab1leftitem"
            :class="{ active: element.process_id === current }"
            @click="handleClick(element.process_id)"
          >
            <div class="tipbox">
              <div class="tip">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>拖动调整顺序</span>
                  </template>
                  <SmileOutlined />
                </a-tooltip>
              </div>
              <div class="deit">
                <EditOutlined
                  @click.stop="handleEdit(element)"
                /><CloseCircleOutlined
                  @click.stop="handleDelete(element.process_id)"
                />
              </div>
            </div>

            <div>{{ element.process_name }}</div>
          </div>
        </template>
      </draggable>
    </div>
    <div class="tab1content">
      <div class="tab1contenttop" v-if="tab1leftList.length !== 0">
        <div class="addnodebox">
          <div class="addnode" draggable="true" @dragend="drag($event, 1)">
            普通节点
          </div>
          <div class="addnode" draggable="true" @dragend="drag($event, 2)">
            跳转节点
          </div>
          <div class="addnode" @click="handleClear">清空画布</div>
          <!-- <div class="addnode" draggable="true" @dragend="drag(4)">IVR节点</div>
          <div class="addnode" draggable="true" @dragend="drag(5)">
            条件判断节点
          </div>
          <div class="addnode" draggable="true" @dragend="drag(6)">
            信息查询节点
          </div> -->
          <!-- <div class="addnode" @click="handleClear">清空画布</div> -->
        </div>
        <div><a-button type="primary" @click="save">保存</a-button></div>
      </div>
      <div class="tab1contentbtn">
        <div id="container"></div>
        <div class="leftbotton">
          <div class="icon" @click="handleCenter">
            <a-tooltip placement="top">
              <template #title>
                <span>定位中心</span>
              </template>
              <AimOutlined />
            </a-tooltip>
          </div>
          <!-- <div class="icon"> -->
          <!-- <a-tooltip placement="top">
              <template #title>
                <span>自动排序</span>
              </template>
              <SlidersOutlined />
            </a-tooltip> -->
          <!-- </div> -->
          <div class="edit">
            <MinusOutlined @click="handleZoom(1)" /><span>{{ zoom }}%</span
            ><PlusOutlined @click="handleZoom(2)" />
          </div>
        </div>
      </div>
    </div>
    <a-modal
      v-model:visible="tab1leftModel"
      title="编辑节点流程"
      centered
      @ok="handleOkEdit"
      class="tab1leftModel"
      :width="400"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
    >
      <a-form>
        <a-form-item label="流程名称" required>
          <a-input placeholder="请输入" v-model:value="inputVal" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="tab1leftModelAdd"
      title="新增流程节点"
      centered
      @ok="handleOk"
      class="tab1leftModelAdd"
      :width="400"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
    >
      <a-form>
        <a-form-item label="节点名称" required>
          <a-input placeholder="请输入" v-model:value="addLeftNode" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="modalDelete"
      title="删除节点"
      centered
      @ok="handleOkDelete"
      :width="400"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
    >
      确认删除吗？
    </a-modal>
    <a-drawer
      :title="drawerTitle"
      placement="right"
      v-model:visible="drawerVisible"
      :width="450"
      class="main-drawer"
      @close="drawerClose"
    >
      <a-form ref="formRef" :model="formState" style="margin-bottom: 50px">
        <a-form-item label="节点名称" required>
          <a-input v-model:value="formState.name" @blur="inputBlur" />
        </a-form-item>
        <a-form-item label="切换顺序">
          <a-radio-group v-model:value="formState.shunxu">
            <a-radio-button value="a">顺序</a-radio-button>
            <a-radio-button value="b">系统时间</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="节点话术">
          <a-textarea
            style="min-height: 96px"
            v-model:value="formState.jiedianhuasu"
            @blur="inputBlur"
          ></a-textarea>
        </a-form-item>
        <!-- <div style="text-align: right">新增话术</div> -->
        <!-- <a-form-item label="对话变量赋值">
          <a-switch></a-switch>
        </a-form-item> -->
        <div v-if="drawerTitle === '编辑普通节点'" class="putong">
          <a-form-item label="回答分支">
            <div class="huidafenzhi">
              <a-checkbox-group v-model:value="formState.answer">
                <a-row>
                  <a-col :span="12">
                    <a-checkbox value="肯定">肯定</a-checkbox>
                    <EditOutlined @click="guanClick('yes')" />
                  </a-col>
                  <a-col :span="12">
                    <a-checkbox value="否定">否定</a-checkbox>
                    <EditOutlined @click="guanClick('no')" />
                  </a-col>
                  <a-col :span="12">
                    <a-checkbox value="拒绝">拒绝</a-checkbox>
                    <EditOutlined @click="guanClick('reject')" />
                  </a-col>
                  <a-col :span="12">
                    <a-checkbox value="默认">默认</a-checkbox>
                    <EditOutlined @click="guanClick('any')" />
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </div>
          </a-form-item>
          <!-- <div class="zidingyi">
            <div>自定义回答分支</div>
            <div class="sss">+新增分支</div>
          </div>
          <div class="zidingyi hahah">
            <a-checkbox-group>
              <a-row>
                <a-col :span="12">
                  <a-checkbox value="A">特殊</a-checkbox>
                </a-col>
                <a-col :span="12">
                  <a-checkbox value="B">复古风</a-checkbox>
                </a-col>
                <a-col :span="12">
                  <a-checkbox value="C">是多少</a-checkbox>
                </a-col>
                <a-col :span="12">
                  <a-checkbox value="D">撒啊啊</a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div> -->
        </div>
        <a-form-item label="下一步" v-else>
          <a-select
            style="width: 100%"
            v-model:value="formState.next"
            @change="nextChange"
          >
            <a-select-option value="1">挂机</a-select-option>
            <a-select-option value="2">下一主动流程</a-select-option>
            <a-select-option value="3">指定主动流程</a-select-option>
            <a-select-option value="4">返回</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="选择流程" required v-if="formState.next === '3'">
          <a-select
            style="width: 100%"
            v-model:value="formState.process"
            placeholder="请选择"
            @change="processChange"
          >
            <a-select-option
              :value="item.process_id"
              v-for="item in tab1leftList"
              :key="item.process_id"
              >{{ item.process_name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="其它设置">
          <a-checkbox-group
            class="group"
            v-model:value="formState.checkboxgroup"
          >
            <div><a-checkbox value="A">不允许用户打断</a-checkbox></div>
            <!-- <div
              v-if="formState.checkboxgroup.includes('A')"
              style="margin-bottom: 15px"
            >
              录音播报至
              <a-input-number
                v-model:value="formState.daduan"
                :min="0"
                :max="100"
                :formatter="(value) => `${value}%`"
                :parser="(value) => value.replace('%', '')"
              />
              之前不支持打断
            </div> -->
            <div style="display: flex; align-items: baseline">
              <a-checkbox value="B">转到人工坐席</a-checkbox>
              <a-tooltip placement="top">
                <template #title>
                  <span>
                    当使用该话术外呼或接待时，触发到当前节点，会发送请求人机切换的通知到所选的人工坐席组中的坐席。坐席收到通知后可点击“开始监听”，监听AI与客户的通话，
                    可切入到通话中，直接与客户对话
                  </span>
                </template>
                <QuestionCircleOutlined />
              </a-tooltip>
            </div>
            <div class="qiehuan" v-if="formState.checkboxgroup.includes('B')">
              <a-form-item label="切换到">
                <a-radio-group
                  style="margin-right: 15px"
                  v-model:value="formState.radiogroup"
                >
                  <a-radio-button value="1">坐席组</a-radio-button>
                  <a-radio-button value="2">电话号码</a-radio-button>
                </a-radio-group>
                <a-tooltip placement="top">
                  <template #title>
                    <span>
                      请设置需要接听的手机号或者固话，未设置外呼转接线路，无法切换到手机号/固话（请先至【通信管理】-【号码管理】中选择一条无主叫或固话线路设置为转接线路，并保证该线路有充足的话费）。
                    </span>
                  </template>
                  <QuestionCircleOutlined />
                </a-tooltip>
              </a-form-item>
              <div v-if="formState.radiogroup == 1">
                <a-form-item label="坐席组" required>
                  <a-select
                    style="width: 100%"
                    placeholder="请选择"
                    v-model:value="formState.zuoxi"
                  >
                    <a-select-option value="11">分担分担</a-select-option>
                    <a-select-option value="12">gdfgf</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="切换后">
                  <a-radio-group v-model:value="formState.qiehuanhou">
                    <a-radio value="1">通知人工监听和切入通话</a-radio>
                    <a-tooltip placement="top">
                      <template #title>
                        <span>
                          AI继续与客户通话，并发送请求人机切换通知到人工坐席
                        </span>
                      </template>
                      <QuestionCircleOutlined />
                    </a-tooltip>
                    <div>
                      <a-radio value="2">等待人工接听</a-radio>
                      <a-tooltip placement="top">
                        <template #title>
                          <span>
                            AI停止与客户对话，播放转人工等待背景音，在人工坐席界面显示来电弹屏，等待人工坐席接听，即转人工。
                          </span>
                        </template>
                        <QuestionCircleOutlined />
                      </a-tooltip>
                    </div>
                  </a-radio-group>
                </a-form-item>
              </div>
              <a-form-item label="电话号码" required v-else>
                <a-input
                  placeholder="请输入"
                  v-model:value="formState.telphone"
                ></a-input>
              </a-form-item>
            </div>
            <div>
              <a-checkbox value="C">发送短信</a-checkbox>
              <a-tooltip placement="top">
                <template #title>
                  <span> 的肌肤的肌肤等丰厚的空间发货 </span>
                </template>
                <QuestionCircleOutlined />
              </a-tooltip>
            </div>
            <div v-if="drawerTitle === '编辑普通节点'">
              <a-checkbox value="D">节点重复</a-checkbox>
              <a-tooltip placement="top">
                <template #title>
                  <span>
                    AI停止与客户对话，播放转人工等待背景音，在人工坐席界面显示来电弹屏，等待人工坐席接听，即转人工。
                  </span>
                </template>
                <QuestionCircleOutlined />
              </a-tooltip>
              <a-select
                style="width: 100px; margin-left: 20px"
                v-model:value="formState.cishu"
                placeholder="请选择"
                v-if="formState.checkboxgroup.includes('D')"
              >
                <a-select-option value="1">1次</a-select-option>
                <a-select-option value="2">2次</a-select-option>
                <a-select-option value="3">3次</a-select-option>
              </a-select>
            </div>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="标签">
          <a-select
            style="width: 100%"
            v-model:value="formState.biaoqian"
            placeholder="请选择"
          >
            <a-select-option
              :value="item.lab_id"
              v-for="item in label_list"
              :key="item.lab_id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="模式">
          <a-select
            style="width: 100%"
            v-model:value="formState.moshi"
            placeholder="请选择"
          >
            <a-select-option value="0">不启动ASR识别</a-select-option>
            <a-select-option value="1">方音的同时开启ASR识别</a-select-option>
            <a-select-option value="2"
              >方音完成之后才开启ASR识别</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="滤噪">
          <a-select
            style="width: 100%"
            v-model:value="formState.luzao"
            placeholder="请选择"
          >
            <a-select-option value="0">0</a-select-option>
            <a-select-option value="0.1">0.1</a-select-option>
            <a-select-option value="0.2">0.2</a-select-option>
            <a-select-option value="0.3">0.3</a-select-option>
            <a-select-option value="0.4">0.4</a-select-option>
            <a-select-option value="0.5">0.5</a-select-option>
            <a-select-option value="0.6">0.6</a-select-option>
            <a-select-option value="0.7">0.7</a-select-option>
            <a-select-option value="0.8">0.8</a-select-option>
            <a-select-option value="0.9">0.9</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="开始">
          <a-input placeholder="请输入" v-model:value="formState.kaishi" />
        </a-form-item>
        <a-form-item label="停止">
          <a-input placeholder="请输入" v-model:value="formState.tingzhi" />
        </a-form-item>
        <a-form-item label="等待">
          <a-input placeholder="请输入" v-model:value="formState.dengdai" />
        </a-form-item>
        <a-form-item label="最大">
          <a-input placeholder="请输入" v-model:value="formState.zuida" />
        </a-form-item>
        <a-form-item label="参数">
          <a-input placeholder="请输入" v-model:value="formState.canshu" />
        </a-form-item>
        <a-form-item label="录音">
          <a-select
            style="width: 100%"
            v-model:value="formState.luyin"
            placeholder="请选择"
          >
            <a-select-option value="0">不录音</a-select-option>
            <a-select-option value="1">检测到声音开始录音</a-select-option>
            <a-select-option value="2">全部录音</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <div class="drawer-buttom">
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="onDrawerOk">确定</a-button>
      </div>
    </a-drawer>
    <a-modal
      v-model:visible="guanModel"
      title="关键词"
      centered
      :width="500"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      class="guanjanci"
      @ok="guanOk"
    >
      <div class="guan-box">
        <a-form>
          <a-form-item label="关键词">
            <a-tag
              style="margin-bottom: 10px"
              closable
              @close="guanLog(item.id)"
              v-for="item in guanArr"
              :key="item.id"
              >{{ item.name }}</a-tag
            >
          </a-form-item>
          <a-form-item label="添加关键词">
            <a-button type="dashed" v-if="addFlag" @click="addFlag = false">
              <PlusOutlined />添加关键词</a-button
            >
            <a-input
              v-model:value="addGuan"
              v-else
              @blur="handleBlue"
            ></a-input>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {
  PlusOutlined,
  EditOutlined,
  CloseCircleOutlined,
  AimOutlined,
  // SlidersOutlined,
  MinusOutlined,
  SmileOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons-vue";
import { reactive, toRefs, ref } from "@vue/reactivity";
import { nextTick, onMounted, watch } from "vue";
import { Graph } from "@antv/x6";
import {
  getFlowList,
  getListAdd,
  getListEdit,
  getListDelete,
  getAllList,
  getNodeChange,
  saveAllNode,
  saveAllLine,
  saveAllChild,
  // getSynonymList,
  // postSynonymAdd,
  getSynonymDetail,
} from "../../service/main";
import { getRuleList } from "../../service/mark.js";
import { message } from "ant-design-vue";
import draggable from "vuedraggable";
import PuTong from "../../components/PuTong.vue";
import TiaoZhuan from "../../components/TiaoZhuan.vue";
import "@antv/x6-vue-shape";
// let sss = 1;
export default {
  name: "Main",
  props: {
    mainId: {
      type: String,
    },
  },
  setup(props) {
    watch(
      () => props.mainId,
      () => {
        state.graph.clearCells();
        init();
      }
    );
    const state = reactive({
      tab1leftList: [],
      current: undefined,
      tab1leftModel: false,
      tab1leftModelAdd: false,
      modalDelete: false,
      inputVal: "",
      graph: "",
      zoom: 100,
      addLeftNode: "",
      editId: undefined,
      nodeList: [],
      child: [],
      edgeList: [],
      drawerVisible: false,
      clickNodeId: undefined,
      dbclickNodeId: undefined,
      nowNode: undefined,
      drawerTitle: undefined,
      guanModel: false,
      guanArr: [],
      addGuan: "",
      addFlag: true,
      addGuantype: "",
      label_list: [],
    });
    const node = reactive({
      x: undefined,
      y: undefined,
      tx: 0,
      ty: 0,
    });
    const formRef = ref();
    const formState = reactive({
      name: "普通话术",
      answer: [],
      shunxu: "a",
      jiedianhuasu: "大家都空腹喝点酒巅峰对决发对方的",
      checkboxgroup: [],
      next: "2",
      process: undefined,
      radiogroup: "1",
      biaoqian: undefined, //标签
      daduan: 100,
      zuoxi: undefined,
      qiehuanhou: "1",
      telphone: undefined,
      cishu: "2",
      // moshi: "1",
      // // vad: "1",
      // luzao: undefined,
      // // dizhi: undefined,
      // kaishi: undefined,
      // tingzhi: undefined,
      // dengdai: undefined,
      // zuida: undefined,
      // canshu: undefined,
      // luyin: undefined,
      // lujing: undefined,
      // geshi: undefined,
    });
    const rules = {
      name: [
        {
          required: true,
          message: "Please input Activity name",
          trigger: "blur",
        },
      ],
    };
    nextTick(() => {
      document.onkeydown = function (e) {
        // console.log(state.clickNodeId)
        if (e.keyCode == 8 && state.clickNodeId && !state.drawerVisible) {
          state.graph.removeNode(state.clickNodeId);
        }
      };
    });
    watch(
      () => formState.answer,
      (newVal, oldval) => {
        // console.log(newVal, oldval);
        if (!state.drawerVisible) return;
        // console.log("哈哈哈");
        if (newVal.length > oldval.length) {
          //添加了一项
          // console.log(newVal[newVal.length - 1]);
          addChildNode(newVal[newVal.length - 1]);
        } else {
          //删除了一项
          // console.log("删除啊！！！")
          for (let i = 0; i < oldval.length; i++) {
            if (!newVal.includes(oldval[i])) {
              // console.log(oldval[i]);
              cutChildNode(oldval[i]);
              return;
            }
          }
        }
      }
    );
    const addChildNode = (val) => {
      const nodes = state.graph.getNodes();
      let node = nodes.find((item) => item.id == state.dbclickNodeId);
      const child = state.graph.addNode({
        width: 30,
        height: 20,
        zIndex: node.zIndex + 1,
        // label: "肯定",
        ports: {
          groups: {
            // 输出链接桩群组定义
            out: {
              position: "bottom",
              attrs: {
                circle: {
                  r: 4,
                  magnet: true,
                  stroke: "#31d0c6",
                  strokeWidth: 2,
                  fill: "#fff",
                },
              },
            },
          },
          items: [
            {
              id: "port",
              group: "out",
            },
          ],
        },
        attrs: {
          body: {
            fill: "#f0f9ff", // 背景颜色
            stroke: "none", // 边框颜色
          },
          label: {
            text: val, // 文本
            fill: "#333", // 文字颜色
            fontSize: 13, // 文字大小
          },
        },
      });
      node.addChild(child);
      child.position(10 + (node._children.length - 1) * 40, 72, {
        relative: true,
      });
    };
    const cutChildNode = (val) => {
      // console.log(val);
      const nodes = state.graph.getNodes();
      let node = nodes.find((item) => item.id == state.dbclickNodeId);
      // console.log(node);
      let son = node._children.find(
        (item) => item.store.data.attrs.label.text === val
      );
      // let index = node._children.findIndex(
      //   (item) => item.store.data.attrs.label.text === val
      // );
      // console.log(son);
      // console.log(node._children[1].store.data.attrs.label.text);
      // node._children.splice(index, 1);
      // console.log(node)
      state.graph.removeNode(son.id);
      // node._children
      for (let i = 0; i < node._children.length; i++) {
        node._children[i].position(10 + i * 40, 72, {
          relative: true,
        });
      }
      // console.log(nodes);
    };
    //获取流程图数据
    const getAllListFun = async (tempId, processId) => {
      const res = await getAllList({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: tempId,
        process_id: processId,
      });
      console.log(res);
      state.nodeList = res.data.data.node_list;
      // console.log(state.nodeList)
      // state.nodeList =  state.nodeList.forEach(item=>{
      //   return {

      //   }
      // })
      state.child = res.data.data.children_list;
      state.edgeList = res.data.data.line_list;
      initGraph();
    };
     onMounted(async () => {
      const res = await getRuleList({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: props.mainId,
      });
      // console.log(res);
      state.label_list = res.data.data.label_list;
      Graph.registerConnector(
        "algo-edge",
        (source, target) => {
          const offset = 4;
          const control = 50;
          const v1 = { x: source.x, y: source.y + offset + control };
          const v2 = { x: target.x, y: target.y - offset - control };
          return `M ${source.x} ${source.y}
       L ${source.x} ${source.y + offset}
       C ${v1.x} ${v1.y} ${v2.x} ${v2.y} ${target.x} ${target.y - offset}
       L ${target.x} ${target.y}
      `;
        },
        true
      );
      state.graph = new Graph({
        container: document.getElementById("container"),
        width: "100%",
        height: "100%",
        panning: true, //允许画布平移
        // selecting: {
        //   enabled: true,
        //   className: "my-selecting",
        //   filter: ["rect"], // 'rect' 类型节点不能被选中
        // },
        grid: {
          size: 10,
          visible: true,
          type: "dot", // 'dot' | 'fixedDot' | 'mesh'
          args: {
            color: "#a0a0a0", // 网格线/点颜色
            thickness: 1, // 网格线宽度/网格点大小
          },
        },
        translating: {
          //限制子节点移动
          restrict(view) {
            const cell = view.cell;
            if (cell.isNode()) {
              const parent = cell.getParent();
              if (parent) {
                return {
                  x: cell.getBBox().x,
                  y: cell.getBBox().y,
                  width: 0,
                  height: 0,
                };
              }
            }
            return null;
          },
        },
        connecting: {
          anchor: "center",
          connector: "algo-edge",
          connectionPoint: "anchor", //使用锚点作为连接点。
          snap: true, //链接桩自动吸附
          allowBlank: false, // 是否允许连接到画布空白位置的点
          allowLoop: false, // 是否允许创建循环连线，即边的起始节点和终止节点为同一节点
          allowNode: false, // 是否允许边链接到节点（非节点上的链接桩）
          createEdge() {
            return state.graph.createEdge({
              attrs: {
                line: {
                  // strokeDasharray: "5 5",
                  stroke: "#3c78ba",
                  strokeWidth: 1,
                  // targetMarker: {
                  //   name: "block",
                  //   args: {
                  //     size: "6",
                  //   },
                  // },
                },
              },
            });
          },
          validateMagnet({ magnet }) {
            return magnet.getAttribute("port-group") !== "in";
          },
          validateConnection({
            // sourceView,
            targetView,
            sourceMagnet,
            targetMagnet,
          }) {
            // 只能从输出链接桩创建连接
            if (
              !sourceMagnet ||
              sourceMagnet.getAttribute("port-group") === "in"
            ) {
              return false;
            }
            // 只能连接到输入链接桩
            if (
              !targetMagnet ||
              targetMagnet.getAttribute("port-group") !== "in"
            ) {
              return false;
            }
            // 判断目标链接桩是否可连接
            const portId = targetMagnet.getAttribute("port");
            const node = targetView.cell;
            const port = node.getPort(portId);
            if (port && port.connected) {
              return false;
            }
            return true;
          },
        },
      });
      state.graph.on("node:dblclick", ({ node }) => {
        console.log(node);
        // console.log(state.arr);
        if (node.port.groups.in) {
          state.drawerVisible = true;
          const { content, title, params } =
            node.store.data.component.data().nodeData;
          formState.jiedianhuasu = content;
          formState.name = title;
          for (let key in params) {
            formState[key] = params[key];
          }
          console.log(node.store.data.component.data().nodeData);

          state.dbclickNodeId = node.id;
          if (node._children) {
            node._children.forEach((item) =>
              formState.answer.push(item.store.data.attrs.label.text)
            );
            state.drawerTitle = "编辑普通节点";
          } else {
            state.drawerTitle = "编辑跳转节点";
          }
        }
      });
      //监听画布平移
      state.graph.on("translate", ({ tx, ty }) => {
        // console.log(tx, ty);
        node.tx = Math.round(tx);
        node.ty = Math.round(ty);
      });
      //tianjia
      // state.graph.on('edge:mouseenter', ({ e, node, view }) => {
      //   console.log(e, node, view)

      //  })
      state.graph.on("edge:mouseenter", ({ edge }) => {
        edge.addTools([
          "source-arrowhead",
          "target-arrowhead",
          {
            name: "button-remove",
            args: {
              distance: -30,
            },
          },
        ]);
      });
      state.graph.on("edge:mouseleave", ({ edge }) => {
        edge.removeTools();
      });

      state.graph.on("node:click", ({ node }) => {
        // const nodes = graph.getNodes();
        // console.log(state.nodeList)
        // console.log(node)
        if (node.port.groups.in) {
          state.clickNodeId = node.id;
          reset();
          node.store.data.component.data().nodeData.active = true;
          // console.log(node.store.data.component.data().nodeData);
          // console.log(node);
          // state.nodeList.forEach((item) => {
          //   if (item.node_ids === node.id) {
          //     item.active = "1";
          //     console.log("aaaaa")
          //     console.log(item.active)
          //   }
          // });
          // console.log(state.nodeList)
        }
      });
      state.graph.on("blank:click", () => {
        state.clickNodeId = undefined;
        reset();
      });
      Graph.registerVueComponent(
        "pu-tong-component",
        {
          template: `<pu-tong></pu-tong>`,
          components: { PuTong },
        },
        true
      );
      Graph.registerVueComponent(
        "tiao-zhuan-component",
        {
          template: `<tiao-zhuan></tiao-zhuan>`,
          components: { TiaoZhuan },
        },
        true
      );
      // getAllListFun()
      init();
      // initGraph();
    }); 
    const init = async () => {
      const res = await getFlowList({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: props.mainId,
      });
      // console.log(res);
      state.tab1leftList = res.data.data;
      // console.log(state);
      if (state.tab1leftList.length > 0) {
        state.current = state.tab1leftList[0].process_id;
      }
      // console.log(props.mainId,state.current)
      getAllListFun(props.mainId, state.current);
    };
    const initGraph = () => {
      // console.log("hahah");
      if (state.nodeList.length === 0) return;
      // console.log(state.nodeList.length)
      for (let i = 0; i < state.nodeList.length; i++) {
        if (state.nodeList[i].type_id === 1) {
          state.nodeList[i].nodeData = {
            title: state.nodeList[i].info_title,
            content: state.nodeList[i].info_talk_info,
            active: false,
            node_id: state.nodeList[i].node_id,
          };
          state.nodeList[i].nodeData.params = JSON.parse(
            state.nodeList[i].node_data
          );
          const parent = state.graph.addNode({
            shape: state.nodeList[i].type,
            x: state.nodeList[i].node_x,
            y: state.nodeList[i].node_y,
            id: state.nodeList[i].node_ids,
            zIndex: state.nodeList[i].node_z_index,
            width: 270,
            add: "sskdsjkdsdskdj",
            ports: {
              groups: {
                // 输入链接桩群组定义
                in: {
                  position: "top",
                  attrs: {
                    circle: {
                      r: 4,
                      magnet: true,
                      stroke: "#31d0c6",
                      strokeWidth: 2,
                      fill: "#fff",
                    },
                  },
                },
              },
              items: [
                {
                  id: "port",
                  group: "in",
                },
              ],
            },
            component: {
              template: `<pu-tong :nodeData="nodeData"></pu-tong>`,
              data() {
                return {
                  nodeData: state.nodeList[i].nodeData,
                };
              },
              components: {
                PuTong,
              },
            },
          });
          state.nodeList[i].children = JSON.parse(state.nodeList[i].children);
          // console.log(state.nodeList[i].children);
          for (let j = 0; j < state.nodeList[i].children.length; j++) {
            const son = state.child.find(
              (item) => item.node_ids === state.nodeList[i].children[j].nodeId
            );
            const child = state.graph.addNode({
              width: 30,
              height: 20,
              zIndex: son.node_z_index,
              id: son.node_ids,
              // label: "肯定",
              ports: {
                groups: {
                  // 输出链接桩群组定义
                  out: {
                    position: "bottom",
                    attrs: {
                      circle: {
                        r: 4,
                        magnet: true,
                        stroke: "#31d0c6",
                        strokeWidth: 2,
                        fill: "#fff",
                      },
                    },
                  },
                },
                items: [
                  {
                    id: "port",
                    group: "out",
                  },
                ],
              },
              attrs: {
                body: {
                  fill: "#f0f9ff", // 背景颜色
                  stroke: "none", // 边框颜色
                },
                label: {
                  text: son.label, // 文本
                  fill: "#333", // 文字颜色
                  fontSize: 13, // 文字大小
                },
              },
            });
            parent.addChild(child);
            child.position(10 + j * 40, 72, {
              relative: true,
            });
          }
        } else if (state.nodeList[i].type_id === 2) {
          // console.log("hahah")
          // const nodeData = {};
          // state.nodeList[i].nodeData.params = JSON.parse(
          //   state.nodeList[i].node_data
          // );
          const { next } = state.nodeList[i];
          let hahah;
          if (next === "1") {
            hahah = "挂机";
          } else if (next === "2" || next === "0") {
            hahah = "执行下一步";
          } else if (next === "3") {
            const { process } = JSON.parse(state.nodeList[i].node_data);
            console.log(JSON.parse(state.nodeList[i].node_data));
            const obj = state.tab1leftList.find(
              (item) => item.process_id === process
            );
            hahah = obj.process_name;
          } else {
            hahah = "返回";
          }
          state.nodeList[i].nodeData = {
            title: state.nodeList[i].info_title,
            content: state.nodeList[i].info_talk_info,
            active: false,
            next: hahah,
            node_id: state.nodeList[i].node_id,
          };
          state.nodeList[i].nodeData.params = JSON.parse(
            state.nodeList[i].node_data
          );
          state.graph.addNode({
            shape: state.nodeList[i].type,
            x: state.nodeList[i].node_x,
            y: state.nodeList[i].node_y,
            id: state.nodeList[i].node_ids,
            zIndex: state.nodeList[i].node_z_index,
            width: 270,
            ports: {
              groups: {
                // 输入链接桩群组定义
                in: {
                  position: "top",
                  attrs: {
                    circle: {
                      r: 4,
                      magnet: true,
                      stroke: "#31d0c6",
                      strokeWidth: 2,
                      fill: "#fff",
                    },
                  },
                },
              },
              items: [
                {
                  id: "port",
                  group: "in",
                },
              ],
            },
            component: {
              template: `<tiao-zhuan :nodeData="nodeData"></tiao-zhuan>`,
              data() {
                return {
                  nodeData: state.nodeList[i].nodeData,
                };
              },
              components: {
                TiaoZhuan,
              },
            },
          });
        }
      }
      if (state.edgeList.length === 0) return;
      for (let i = 0; i < state.edgeList.length; i++) {
        let color;
        let obj = state.child.find(
          (item) => item.node_ids === state.edgeList[i].source
        );
        switch (obj.label) {
          case "肯定":
            color = "#339933";
            break;
          case "否定":
            color = "#FF0033";
            break;
          case "默认":
            color = "#666666";
            break;
          case "拒绝":
            color = "#FF0033";
            break;
        }
        state.graph.addEdge({
          source: {
            cell: state.edgeList[i].source,
            port: "port",
          }, // 源节点和链接桩 ID
          target: {
            cell: state.edgeList[i].target,
            port: "port",
          }, // 目标节点 ID 和链接桩 ID
          attrs: {
            line: {
              stroke: color,
              strokeWidth: 1,
            },
          },
        });
      }
      // state.graph.resetCells()
    };
    const reset = () => {
      // state.graph.drawBackground({ color: "#fff" });
      const nodes = state.graph.getNodes();
      nodes.forEach((node) => {
        if (node.shape === "vue-shape") {
          node.store.data.component.data().nodeData.active = false;
          // console.log(node.store.data.component.data().nodeData.active)
        }
      });
      // state.nodeList.forEach((item) => (item.active = '2'));
      console.log(nodes);
    };
    // const answerChange=(e)=>{
    //   console.log(e)
    // }
    const handleEdit = (item) => {
      // console.log(id, num);
      state.clickNodeId = undefined;
      state.tab1leftModel = true;
      state.inputVal = item.process_name;
      state.editId = item.process_id;
    };
    const handleClick = (id) => {
      state.current = id;
      state.graph.clearCells();
      getAllListFun(props.mainId, state.current);
      // initGraph();
    };
    //添加主流程
    const handleOk = async () => {
      console.log(state.addLeftNode.length);
      if (!state.addLeftNode.trim()) {
        message.warning("新增节点不能为空");
        return;
      } else if (state.addLeftNode.length > 12) {
        message.warning("新增节点不能大于12个字符");
        return;
      }
      const res = await getListAdd({
        access_token: sessionStorage.getItem("token"),
        process_name: state.addLeftNode,
        talk_temp_id: props.mainId,
      });

      console.log(res);
      state.graph.clearCells();
      state.tab1leftModelAdd = false;
      state.addLeftNode = "";
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        init();
        return;
      } else {
        message.error(res.data.msg);
        return;
      }
    };
    //编辑主流程
    const handleOkEdit = async () => {
      if (!state.inputVal.trim()) {
        message.warning("节点名称不能为空");
        return;
      } else if (state.inputVal.length > 12) {
        message.warning("新增节点不能大于12个字符");
        return;
      }
      console.log(state.inputVal, state.editId, props.mainId);
      const res = await getListEdit({
        access_token: sessionStorage.getItem("token"),
        process_name: state.inputVal,
        process_id: state.editId,
        talk_temp_id: props.mainId,
      });
      // console.log(res)
      // console.log(123);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        state.tab1leftModel = false;
        init();
      } else {
        message.error(res.data.msg);
        return;
      }
    };
    const handleDelete = (process_id) => {
      state.modalDelete = true;
      state.editId = process_id;
    };
    const handleOkDelete = async () => {
      state.graph.clearCells();
      const res = await getListDelete({
        access_token: sessionStorage.getItem("token"),
        process_id: state.editId,
      });
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        state.modalDelete = false;
        init();
      }
    };
    const handleAdd = () => {
      state.tab1leftModelAdd = true;
      state.clickNodeId = undefined;
    };
    const handleCenter = () => {
      console.log("定位中心");
      state.graph.centerContent();
    };
    const handleZoom = (num) => {
      // if (state.zoom == 0 || state.zoom == 130) return
      if (num == 1) {
        state.graph.zoom(-0.1);
        state.zoom -= 10;
      } else {
        state.graph.zoom(0.1);
        state.zoom += 10;
      }
    };
    const drag = (e, num) => {
      // console.log(e, num);
      node.x = e.pageX - 280 - node.tx;
      node.y = e.pageY - 180 - node.ty;
      // console.log(node.x, node.y);
      switch (num) {
        case 1:
          addPutong();
          break;
        case 2:
          addTiaozhuan();
          break;
        // case 3:
        //   state.graph.clearCells();
        //   break;
      }
    };
    const addPutong = () => {
      let num = state.nodeList.length;
      const obj = {
        nodeId: num,
        nodeData: {
          title: "普通节点",
          content: "对方的客服好的发电恢发来的多发点福利都发都发了",
          active: false,
        },
      };
      state.nodeList.push(obj);
      const parent = state.graph.addNode({
        shape: "vue-shape",
        x: node.x,
        y: node.y,
        width: 270,
        ports: {
          groups: {
            // 输入链接桩群组定义
            in: {
              position: "top",
              attrs: {
                circle: {
                  r: 4,
                  magnet: true,
                  stroke: "#31d0c6",
                  strokeWidth: 2,
                  fill: "#fff",
                },
              },
            },
          },
          items: [
            {
              id: "port",
              group: "in",
            },
          ],
        },
        component: {
          template: `<pu-tong :nodeData="nodeData"></pu-tong>`,
          data() {
            return {
              nodeData: state.nodeList[num].nodeData,
            };
          },
          // watch: {
          //   nodeData(oldval, newval) {
          //     console.log(oldval, newval);
          //   },
          // },
          // provide:['nodeData'],
          components: {
            PuTong,
          },
        },
      });
      state.nodeList[num].nodeId = parent.id;
      let arr = ["肯定", "否定", "拒绝", "默认"];
      for (let i = 0; i < arr.length; i++) {
        const child = state.graph.addNode({
          width: 30,
          height: 20,
          ports: {
            groups: {
              // 输出链接桩群组定义
              out: {
                position: "bottom",
                attrs: {
                  circle: {
                    r: 4,
                    magnet: true,
                    stroke: "#31d0c6",
                    strokeWidth: 2,
                    fill: "#fff",
                  },
                },
              },
            },
            items: [
              {
                id: "port",
                group: "out",
              },
            ],
          },
          attrs: {
            body: {
              fill: "#f0f9ff", // 背景颜色
              stroke: "none", // 边框颜色
            },
            label: {
              text: arr[i], // 文本
              fill: "#333", // 文字颜色
              fontSize: 13, // 文字大小
            },
          },
        });
        parent.addChild(child);
        child.position(10 + i * 40, 72, {
          relative: true,
        });
      }
    };
    const addTiaozhuan = () => {
      let num = state.nodeList.length;
      const obj = {
        nodeId: num,
        nodeData: {
          content: "",
          title: "跳转节点",
          next: "执行下一步",
          active: false,
        },
      };
      state.nodeList.push(obj);
      const addnode = state.graph.addNode({
        shape: "vue-shape",
        x: node.x,
        y: node.y,
        // zIndex: 1,
        width: 270,
        ports: {
          groups: {
            // 输入链接桩群组定义
            in: {
              position: "top",
              attrs: {
                circle: {
                  r: 4,
                  magnet: true,
                  stroke: "#31d0c6",
                  strokeWidth: 2,
                  fill: "#fff",
                },
              },
            },
          },
          items: [
            {
              id: "port",
              group: "in",
            },
          ],
        },
        component: {
          template: `<tiao-zhuan :nodeData="nodeData"></tiao-zhuan>`,
          data() {
            return {
              nodeData: state.nodeList[num].nodeData,
            };
          },
          components: {
            TiaoZhuan,
          },
        },
      });
      state.nodeList[num].nodeId = addnode.id;
    };
    const save = async () => {
      // console.log(state.graph.toJSON());
      // console.log(state.graph.getNodes());
      let result = state.graph.toJSON().cells;
      let node = [];
      let child = [];
      let edge = [];
      for (let i = 0; i < result.length; i++) {
        if (result[i].shape === "vue-shape") {
          if (result[i].children) {
            //普通节点
            let obj = {
              typeId: 1,
              type: result[i].shape,
              nodeId: result[i].id,
              x: result[i].position.x,
              y: result[i].position.y,
              zIndex: result[i].zIndex,
              nodeData: result[i].component.data().nodeData,
              children: [],
              // params: result[i].component.data().nodeData.params,
            };
            // obj.nodeData.params = obj.nodeData.params;
            // console.log(result[i].component.data().nodeData.params);
            // console.log(obj.params)
            result.forEach((item) => {
              if (
                item.shape === "rect" &&
                result[i].children.includes(item.id)
              ) {
                obj.children.push({
                  nodeId: item.id,
                });
              }
            });
            node.push(obj);
          } else {
            //跳转节点
            let obj = {
              typeId: 2,
              type: result[i].shape,
              nodeId: result[i].id,
              x: result[i].position.x,
              y: result[i].position.y,
              zIndex: result[i].zIndex,
              nodeData: result[i].component.data().nodeData,
              // params: result[i].params,
            };
            // console.log(result[i].component.data().nodeData.params);
            node.push(obj);
          }
        } else if (result[i].shape === "edge") {
          //链接线
          let obj = {
            // type: result[i].shape,
            // nodeId: result[i].id,
            zIndex: result[i].zIndex,
            source: result[i].source.cell,
            target: result[i].target.cell,
            stroke: result[i].attrs.line.stroke,
          };
          edge.push(obj);
        } else {
          let obj = {
            type: result[i].shape,
            nodeId: result[i].id,
            label: result[i].attrs.label.text,
            zIndex: result[i].zIndex,
            x: result[i].position.x,
            y: result[i].position.y,
          };
          child.push(obj);
        }
      }
      console.log(node, edge, child);
      let next = true;
      node.forEach((item) => {
        if (item.typeId === 1 && !item.nodeData.content) {
          message.error("节点内容不能为空");
          next = false;
          return;
        }
      });
      // state.nodeList = node;
      // state.child = child;
      // state.edgeList = edge;
      if (!next) return;
      const res = await saveAllNode({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: props.mainId,
        process_id: state.current,
        arr: JSON.stringify(node),
      });
      console.log(res);

      const res2 = await saveAllChild({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: props.mainId,
        process_id: state.current,
        arr: JSON.stringify(child),
      });
      console.log(res2);
      const res3 = await saveAllLine({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: props.mainId,
        process_id: state.current,
        arr: JSON.stringify(edge),
      });
      console.log(res3);
      if (res3.data.status === 100000) {
        message.success(`保存成功`);
      } else if (res3.data.status === "100003") {
        message.error(`保存失败,${res3.data.msg}`);
      } else {
        message.error(res3.data.msg);
      }
    };
    const onClose = () => {
      console.log("关闭");
      formState.name = "普通话术";
      formState.answer = [];
      formState.shunxu = "a";
      formState.jiedianhuasu = "大家都空腹喝点酒巅峰对决发对方的";
      formState.checkboxgroup = [];
      formState.next = "2";
      formState.process = undefined;
      formState.radiogroup = "1";
      formState.biaoqian = undefined; //标签
      formState.daduan = 100;
      formState.zuoxi = undefined;
      formState.qiehuanhou = "1";
      formState.telphone = undefined;
      formState.cishu = "2";
      state.dbclickNodeId = undefined;
      state.drawerVisible = false;
      // formState.moshi = "1";
      // formState.luzao = undefined;
      // formState.kaishi = undefined;
      // formState.tingzhi = undefined;
      // formState.dengdai = undefined;
      // formState.zuida = undefined;
      // formState.canshu = undefined;
      // formState.luyin = undefined;
    };
    const onDrawerOk = () => {
      console.log(state.dbclickNodeId);
      const nodes = state.graph.getNodes();
      let obj = nodes.find((item) => item.id === state.dbclickNodeId);
      // console.log(state.nodeList)
      let params = {
        checkboxgroup: formState.checkboxgroup,
        shunxu: formState.shunxu,
        biaoqian: formState.biaoqian,
        // moshi: formState.moshi,
        // // vad: formState.vad,
        // luzao: formState.luzao,
        // // dizhi: formState.dizhi,
        // kaishi: formState.kaishi,
        // tingzhi: formState.tingzhi,
        // dengdai: formState.dengdai,
        // zuida: formState.zuida,
        // canshu: formState.canshu,
        // luyin: formState.luyin,
        // lujing: formState.lujing,
        // geshi: formState.geshi,
      };
      // console.log(obj);

      if (formState.checkboxgroup.includes("A")) {
        params.daduan = formState.daduan;
      }
      if (formState.checkboxgroup.includes("B")) {
        params.radiogroup = formState.radiogroup;
        if (formState.radiogroup === "1") {
          params.zuoxi = formState.zuoxi;
          params.qiehuanhou = formState.qiehuanhou;
        } else {
          params.telphone = formState.telphone;
        }
      }
      if (formState.checkboxgroup.includes("D")) {
        params.cishu = formState.cishu;
      }
      if (obj._children) {
        //普通节点
      } else {
        //跳转节点
        params.next = formState.next;
        if (formState.next === "3") {
          params.process = formState.process;
        }
      }
      obj.store.data.component.data().nodeData.params = params;
      onClose();
      state.drawerVisible = false;
    };
    //内容改变事件
    const inputBlur = () => {
      const nodes = state.graph.getNodes();
      let obj = nodes.find((item) => item.id == state.dbclickNodeId);
      obj.store.data.component.data().nodeData.content = formState.jiedianhuasu;
      obj.store.data.component.data().nodeData.title = formState.name;
      // console.log(obj.store.data.component.data().nodeData);
      // console.log(nodes)
    };
    const showDrawer = () => {
      state.drawerVisible = true;
    };
    const dragChange = async () => {
      // console.log(state.tab1leftList);
      const params = state.tab1leftList.map((item) => {
        return {
          process_id: item.process_id,
        };
      });
      console.log(params);
      const res = await getNodeChange({
        access_token: sessionStorage.getItem("token"),
        // talk_temp_id: props.mainId,
        tran: JSON.stringify(params),
      });
      console.log(res);
    };
    const drawerClose = () => {
      formState.answer = [];
      onClose();
    };
    const handleClear = () => {
      state.graph.clearCells();
    };
    const nextChange = (e) => {
      console.log(e);
      const nodes = state.graph.getNodes();
      let obj = nodes.find((item) => item.id == state.dbclickNodeId);
      if (e === "1") {
        obj.store.data.component.data().nodeData.next = "挂机";
      } else if (e === "2") {
        obj.store.data.component.data().nodeData.next = "执行下一步";
      } else if (e === "4") {
        obj.store.data.component.data().nodeData.next = "返回";
      }
    };
    const processChange = (e) => {
      console.log(e);
      const nodes = state.graph.getNodes();
      let obj = nodes.find((item) => item.id == state.dbclickNodeId);
      let obj2 = state.tab1leftList.find((item) => item.process_id === e);
      obj.store.data.component.data().nodeData.next = obj2.process_name;
    };
    const guanClick = async (num) => {
      console.log(num);
      state.guanModel = true;
      state.addGuantype = num;
      const nodes = state.graph.getNodes();
      let obj = nodes.find((item) => item.id == state.dbclickNodeId);
      // console.log(obj.store.data.component.data().nodeData[num]);
      state.guanArr = [];

      if (obj.store.data.component.data().nodeData[num]) {
        obj.store.data.component
          .data()
          .nodeData[num].split(",")
          .forEach((item, index) => {
            state.guanArr.push({
              name: item,
              id: index,
            });
          });
        return;
      }
      const res = await getSynonymDetail({
        access_token: sessionStorage.getItem("token"),
        type: num,
        node_id: state.dbclickNodeId,
      });
      // console.log(res);
      res.data.data.forEach((item, index) => {
        state.guanArr.push({
          name: item.sls_name,
          id: index,
        });
      });
      // console.log(state.guanArr);
    };
    const handleBlue = () => {
      console.log("sdsi");
      if (!state.addGuan.trim()) {
        state.addFlag = true;
        return;
      }
      let obj = {
        name: state.addGuan,
        id: new Date().getTime(),
      };
      state.guanArr.push(obj);
      state.addGuan = "";
      state.addFlag = true;
    };
    const guanOk = () => {
      console.log(state);
      const nodes = state.graph.getNodes();
      console.log(nodes);
      let obj = nodes.find((item) => item.id == state.dbclickNodeId);
      console.log(obj);
      let str = "";
      state.guanArr.forEach((item) => (str += item.name + ","));
      str = str.substr(0, str.length - 1);
      console.log(str);
      obj.store.data.component.data().nodeData[state.addGuantype] = str;
      state.guanModel = false;
    };
    const guanLog = (id) => {
      let index = state.guanArr.findIndex((item) => item.id === id);
      state.guanArr.splice(index, 1);
    };
    return {
      ...toRefs(state),
      handleEdit,
      handleClick,
      handleOk,
      handleAdd,
      handleCenter,
      handleZoom,
      handleOkEdit,
      drag,
      addTiaozhuan,
      addPutong,
      ...toRefs(node),
      handleDelete,
      handleOkDelete,
      save,
      showDrawer,
      onClose,
      onDrawerOk,
      formRef,
      formState,
      rules,
      dragChange,
      cutChildNode,
      addChildNode,
      drawerClose,
      getAllListFun,
      inputBlur,
      handleClear,
      processChange,
      nextChange,
      guanClick,
      handleBlue,
      guanOk,
      guanLog,
    };
  },
  components: {
    draggable,
    PlusOutlined,
    EditOutlined,
    CloseCircleOutlined,
    AimOutlined,
    // SlidersOutlined,
    MinusOutlined,
    SmileOutlined,
    QuestionCircleOutlined,
  },
};
</script>
<style>
/* rect,
g text tspan,
g text {
  cursor: pointer;
} */
.x6-node text {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.x6-cell .x6-port {
  opacity: 0;
}
.main-drawer .ant-form-item-label {
  min-width: 100px;
}
.main-drawer .qiehuan .ant-form-item-label {
  min-width: 81px;
}
.main-drawer .qiehuan .ant-form-item {
  margin-bottom: 10px;
}
.guanjanci .ant-form-item-label {
  min-width: 83px;
}
.guanjanci .ant-modal-body {
  min-height: 178px;
}
.ant-typography {
  line-height: 36px;
}
.guan-box {
  max-height: 300px;
  overflow: auto;
}
.guan-box::-webkit-scrollbar {
  display: none;
}
</style>
<style  scoped>
.anticon-close-circle {
  margin: 8px;
}
.tab1 {
  padding: 0 10px 10px 10px;
  display: flex;
  width: 100%;
  height: 100%;
}
.tab1left,
.tab2left {
  width: 140px;
  border-right: 1px solid #ccc;
  height: 100%;
  padding-right: 10px;
}

.tab1contenttop,
.tab2contenttop,
.tab4contenttop {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  width: 100%;
}
.tab1content,
.tab2content {
  width: calc(100% - 140px);
}
.tab1leftitem {
  background-color: #f8f9fb;
  height: 80px;
  margin-top: 10px;
  padding-left: 8px;
  cursor: pointer;
  border-radius: 3px;
}
.tab1leftitem:hover .deit,
.tab1leftitem:hover .tip {
  visibility: visible;
}
.deit {
  text-align: right;
  visibility: hidden;
}
.tip {
  visibility: hidden;
}
.active {
  background-color: #fff;
  border: 1px solid #1890ff;
}
.deit .anticon:hover {
  color: #1890ff;
}
.tab1leftModel .ant-modal-body .ant-form-item {
  margin-bottom: 0px;
}
#container {
  height: 100%;
  /* height: calc(100% - 42px); */
  background-color: #f9f9f9;
  /* margin-left: 10px;
  margin-top: 10px; */
}
.tab1contentbtn {
  position: relative;
  margin-left: 10px;
  margin-top: 10px;
  height: calc(100% - 42px);
  background-color: #f9f9f9;
}
.leftbotton {
  position: absolute;
  left: 15px;
  bottom: 15px;
  display: flex;
}
.leftbotton .icon {
  width: 32px;
  height: 32px;
  background-color: #fff;
  margin-right: 15px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  color: #666;
}
.edit {
  height: 32px;
  display: flex;
  align-items: center;
}
.edit span {
  margin: 0 4px;
}
.edit .anticon {
  color: #fff;
  background-color: #ccc;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}
.addnodebox {
  display: flex;
}
.addnode {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  font-size: 12px;
  cursor: pointer;
}
.main-drawer .putong .ant-form-item {
  margin-bottom: 0;
}
.huidafenzhi {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e0dbdb;
}
.huidafenzhi > div {
  margin-bottom: 4px;
}
.zidingyi {
  margin-left: 100px;
  display: flex;
}
/* .zidingyi:nth-child(1){
  border-bottom: 1px solid #ccc;
} */
.zidingyi .sss {
  margin-left: 50px;
  color: #1890ff;
}
.hahah {
  margin-bottom: 15px;
}
.group {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
}
.ant-checkbox-wrapper {
  margin-left: 0;
  margin-bottom: 15px;
}
.drawer-buttom {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
.tipbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.anticon-smile {
  color: rgb(141, 137, 137);
}
/* .my-selecting {
  border: 1px solid #1890ff;
} */
/* .textarea{
  height: 74px !important;
} */
.huidafenzhi .anticon-edit:hover {
  cursor: pointer;
  color: #1890ff;
}
</style>
<style scoped>
.huidafenzhi[data-v-5fe623f8] {
  border-bottom: 0;
}
</style>