import http from "../axios/http"

//获取热词列表
export let getHostList = (params) => {
    return http.get("/hot/hot_list", { params })
}
//获取热词列表
export let getHotEdit = (params) => {
    return http.post("/hot/hot_edit", params)
}
//热词添加，编辑
export let postHotAdd = (params) => {
    return http.post("/hot/hot_add", params)
}
//修改话术权重接口
export let postHotWeight = (params) => {
    return http.post("/hot/hot_weight", params)
}
//意向标签规则列表
export let getRuleList = (params) => {
    return http.get("/rule/rule_list", { params })
}
//意向标签条件列表
export let getConditionList = (params) => {
    return http.get("/condition/condition_list", { params })
}
//意向标签规则添加
export let postRuleAdd = (params) => {
    return http.post("/rule/rule_add", params)
}
//意向标签规则添加
export let postRuleDel = (params) => {
    return http.post("/rule/rule_del", params)
}
//意向标签规则编辑
export let postRuleEdit = (params) => {
    return http.post("/rule/rule_edit", params)
}
//话术审核列表
export let getExamineList = (params) => {
    return http.get("/examine/examine_list", { params })
}
//话术审核取消
export let postCancel = (params) => {
    return http.post("/examine/cancel", params)
}
//ASR系统设置（默认配置）
export let getAsrList = (params) => {
    return http.get("/speech_set/detail", { params })
}
//ASR系统设置保存（默认配置）
export let postAsrSave = (params) => {
    return http.post("/speech_set/setting", params)
}
//保存意向标签排序
export let postTranSave = (params) => {
    return http.post("/rule/rule_tran", params)
}
