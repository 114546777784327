<template>
  <div class="TiaoZhuan" :class="{ active: nodeData.active  }">
    <div class="title">{{ nodeData.title }}</div>
    <div class="content" v-if="nodeData.content">{{ nodeData.content }}</div>
    <div class="next">下一步：{{ nodeData.next }}</div>
  </div>
</template>
<script>
// import { reactive, toRefs } from "@vue/reactivity";
export default {
  name: "TiaoZhuan",
  props: {
    nodeData: Object,
  },
  setup(props) {
    console.log(props);
    // const state = reactive({
    //   isBorder: false,
    // });
    // const handlick = () => {
    //   state.isBorder = true;
    // };
    // return { handlick, ...toRefs(state) };
  },
};
</script>
<style scoped>
.active {
  border: 1px solid #1890ff;
}
.TiaoZhuan {
  width: 270px;
  /* height: 100px; */
  background-color: #fff;
  text-align: left;
  border-radius: 3px;
  padding: 10px 10px 6px 10px;
  box-sizing: border-box;
}
.content {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.next {
  font-size: 13px;
}
</style>

