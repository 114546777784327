import http from "../axios/http"

//tts声音配置的选择
export let getSystemList = (params) => {
    return http.get("/examine/tts_system", { params })
}
//tts编辑配置参数
export let postTtsEdit = (params) => {
    return http.post("/examine/tts_edit", params)
}
//节点录音列表
export let getNodeWav = (params) => {
    return http.get("/examine/node_wav", { params })
}
//tts转换内容转语音接口
export let postTts = (params) => {
    return http.post("/examine/tts_content", params)
}
//节点上传
export let postUploadwav = (params) => {
    return http.post("/examine/upload_node_wav", params)
}
//节点上传/知识库
export let postUploadTechwav = (params) => {
    return http.post("/examine/upload_tech_wav", params)
}
//是否自己录音
export let getDownload = (params) => {
    return http.get("/examine/download_node_wav", { params })
}
//是否自己录音/知识库
export let getDownloads = (params) => {
    return http.get("/examine/download_tech_wav", { params })
}
//删除自己录音
export let postWavDel = (params) => {
    return http.post("/examine/del_node_wav", params)
}
//删除自己录音
export let postTechDel = (params) => {
    return http.post("/examine/del_tech_wav", params)
}
//删除自己录音
export let postMulDel = (params) => {
    return http.post("/examine/del_mul_node_wav", params)
}
//删除全部录音
export let postClearAll = (params) => {
    return http.post("/examine/del_wav", params)
}
//知识库节点录音列表
export let getTechWav = (params) => {
    return http.get("/examine/tech_wav", { params })
}
//话术审核
export let postTijiao = (params) => {
    return http.post("/examine/speech_examine", params)
}
//知识库列表
export let getMulWav = (params) => {
    return http.get("/examine/node_mul_wav", { params })
}
//知识库录音下载
export let getDownloadss = (params) => {
    return http.get("/examine/download_mul_node_wav", { params })
}
//多轮会话上传录音录
export let postUploadDuolun = (params) => {
    return http.post("/examine/upload_mul_node_wav", params)
}