<template>
  <div class="PuTong" :class="{ active: nodeData.active }">
    <div class="title">{{ nodeData.title }}</div>
    <div class="content">{{ nodeData.content }}</div>
  </div>
</template>
<script>
// import { watch } from "@vue/runtime-core";
// import {  watchEffect } from "@vue/runtime-core";
export default {
  name: "PuTong",
  props: {
    nodeData: Object,
  },
  // inject: ["nodeData"],
  // watch: {
  //   nodeData(oldval, newval) {
  //     console.log(oldval, newval);
  //   },
  // },
  setup() {
    // watch(
    //   () => props.nodeData,
    //   (oldval, newval) => {
    //     console.log(oldval, newval);
    //   }
    // );
    // console.log(nodeData);
    // watchEffect(
    //   () => props.nodeData,
    //   (old, newold) => {
    //     console.log(old, newold);
    //   }
    // );
    // console.log(props.nodeData);
    // console.log(getGraph, getNode);
    // const changeContent=(title,content)=>{
    //    console.log(title,content);
    // }
    // return{changeContent}
  },
};
</script>
<style scoped>
.active {
  border: 1px solid #1890ff;
}
.PuTong {
  width: 270px;
  height: 100px;
  background-color: #fff;
  text-align: left;
  border-radius: 3px;
  padding: 10px;
  box-sizing: border-box;
}
.content {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

