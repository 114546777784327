<template>
  <div class="SetUp">
    <a-form>
      <a-form-item label="任务名称">
        <!-- <a-input value="教育培训" placeholder="请输入" style="width: 250px" /> -->
        {{ formState.talk_temp_name }}
      </a-form-item>
      <a-form-item label="场景">
        <span> {{ formState.class_name }}</span>
      </a-form-item>
      <!-- <a-form-item label="声学模型">
        <span>通用模型</span>
      </a-form-item> -->
      <a-form-item label="任务描述">
        <a-textarea
          placeholder="请输入"
          v-model:value="formState.desc"
          style="width: 250px"
        />
      </a-form-item>
      <!-- <a-form-item label="方言类型">
        <a-select value="shanghai" style="width: 250px">
          <a-select-option value="shanghai">普通话</a-select-option>
          <a-select-option value="beijing">上海话</a-select-option>
          <a-select-option value="suzhou">粤语</a-select-option>
        </a-select>
      </a-form-item> -->
      <a-form-item label="优先级匹配">
        <a-select v-model:value="formState.priority" style="width: 250px">
          <a-select-option value="1">主流程优先</a-select-option>
          <a-select-option value="2">知识库优先</a-select-option>
          <!-- <a-select-option value="suzhou">算法优先</a-select-option> -->
        </a-select>
      </a-form-item>
      <!-- <a-form-item label="语言识别灵敏度">
        <a-input value="2400" style="width: 100px" />
      </a-form-item>
      <div class="tips">
        调整机器语音识别的灵敏度，当环境嘈杂可降低灵敏度进行过滤，当客户音量过低，可提高灵敏度
      </div>
      <a-form-item label="客户不回答时间间隔">
        <a-input value="5" style="width: 100px" /><span class="time">秒</span>
      </a-form-item>
      <a-form-item label="最大通话时长">
        <a-input value="5" style="width: 100px" /><span class="time">分钟</span>
      </a-form-item> -->
      <div class="tips">
        <a-button type="primary" @click="handleSave">保存</a-button>
      </div>
      <div class="tips" style="color: red; margin-top: 20px">
        提示：修改优先级匹配请重新提交话术审核
      </div>
    </a-form>
  </div>
</template>
<script>
import { reactive } from "@vue/reactivity";
import { speechedit, postEdit } from "../../service/setup";
import { onMounted, watch } from "@vue/runtime-core";
import { message } from "ant-design-vue";
export default {
  name: "SetUp",
  props: ["mainId"],
  setup(props) {
    console.log(props)
    const formState = reactive({
      talk_temp_name: "",
      class_name: "",
      desc: "",
      priority: "1",
    });
    watch(
      () => props.mainId,
      () => {
        init();
      }
    );
    onMounted(() => {
      if (!props.mainId) return;
      init();
    });
    const init = async () => {
      const res = await speechedit({
        access_token: sessionStorage.getItem("token"),
        speech_id: props.mainId,
      });
      // console.log(res);
      const { class_name, talk_temp_name, priority, desc } = res.data.data;
      formState.talk_temp_name = talk_temp_name;
      formState.priority = priority;
      formState.desc = desc;
      formState.class_name = class_name.join("/");
    };
    const handleSave = async () => {
      const res = await postEdit({
        access_token: sessionStorage.getItem("token"),
        speech_id: props.mainId,
        priority: formState.priority,
        desc: formState.desc,
      });
      if (res.data.status === 100000) {
        message.success(res.data.msg);
      } else {
        message.error(res.data.msg);
      }
    };
    return { formState, handleSave };
  },
  components: {},
};
</script>
<style>
.SetUp .ant-form-item-label {
  min-width: 120px;
}
</style>
<style  scoped>
.SetUp {
  padding: 10px;
}
.tips {
  margin-left: 120px;
}
</style>