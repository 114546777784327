<template>
  <div class="Mark">
    <div class="mark-top">
      <ArrowLeftOutlined @click="routerBack" />
      <a-select style="width: 200px" v-model:value="mainId" @change="init()">
        <a-select-option
          v-for="item in options"
          :key="item.talk_temp_id"
          :value="item.talk_temp_id + ''"
          >{{ item.talk_temp_name }}</a-select-option
        >
        <!-- <a-select-option value="lucy">Lucy</a-select-option>
        <a-select-option value="Yiminghe">yiminghe</a-select-option> -->
      </a-select>
    </div>
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="流程">
        <Main :mainId="mainId" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="知识库">
        <KnowledgeBase
          :mainId="mainId"
          :classifyId="classifyId"
          :label_list="label_list"
        />
      </a-tab-pane>
      <a-tab-pane key="13" tab="多轮会话">
        <Duolun :mainId="mainId" />
      </a-tab-pane>
      <a-tab-pane key="15" tab="全局配置">
        <GlobalConfiguration :mainId="mainId" />
      </a-tab-pane>
      <!-- <a-tab-pane key="3" tab="热词">
        <div class="tab3">
          <div class="tab3top">
            <div class="tab3left">晏伟更新于2021-07-27 16:23:24</div>
            <div class="tab3right">
              <div>
                全局权重:
                <a-input-number
                  v-model:value="hotValue"
                  :min="1"
                  :max="5"
                  size="small"
                  @blur="handleWeight"
                />
              </div>
              <div style="margin: 0 8px">
                当前热词：{{ hotList.length }}/128个
              </div>
              <a-button type="primary" v-if="hotEditdsds" @click="hotEdit"
                >热词编辑</a-button
              >
              <div v-else>
                <a-button @click="quxiao">取消</a-button>
                <a-button
                  type="primary"
                  style="margin-left: 6px"
                  @click="saveHot"
                  >保存</a-button
                >
              </div>
            </div>
          </div>
          <div class="tab3content" v-if="hotEditdsds">
            <div class="father">
              <div
                class="tab3contentitem"
                v-for="item in hotList"
                :key="item.hw_id"
                @click="hotVisbEdit(item)"
              >
                {{ item.name }}×{{ item.sort }}
              </div>
            </div>
          </div>
          <a-textarea
            v-else
            placeholder="请输入"
            class="tab3content"
            v-model:value="textareaVal"
          ></a-textarea>
        </div>
      </a-tab-pane> -->
      <a-tab-pane key="4" tab="意向标签">
        <div class="tab4">
          <div class="tab4contenttop">
            <div
              style="width: 100%; display: flex; justify-content: space-between"
            >
              <a-button type="primary" @click="addRule">添加规则</a-button>
              <div style="margin-right: 10px; color: #666">
                上下拖动调整顺序,完成保存<a-button
                  type="primary"
                  style="margin-left: 10px"
                  @click="savePaixu"
                  >保存排序</a-button
                >
              </div>
            </div>
          </div>
          <a-table
            :columns="columns"
            :data-source="ruleList"
            rowKey="sr_id"
            id="sssssssssssss"
            :pagination="{
              pageSize: 100,
              hideOnSinglePage: true,
            }"
          >
            <template #lab_id="{ record }">
              <span>{{ handleYixiang(label_list, record.lab_id) }}</span>
            </template>
            <template #operation="{ record }">
              <a-button type="primary" size="small" @click="handleEdit(record)"
                >编辑</a-button
              >
              <a-popconfirm
                title="确定删除吗?"
                @confirm="onDelete(record.sr_id)"
              >
                <a-button size="small">删除</a-button>
              </a-popconfirm>
            </template>
          </a-table>
        </div>
      </a-tab-pane>
      <!-- <a-tab-pane key="5" tab="词典">
        <div class="tab5">
          <div class="tab5contenttop">
            <div style="margin-bottom: 15px">
              <a-input-search placeholder="搜索词典" enter-button />
            </div>
            <div>
              <a-button type="primary">添加</a-button>
            </div>
          </div>
          <a-table :columns="columnsCidian" :data-source="[]"></a-table>
        </div>
      </a-tab-pane> -->
      <!-- <a-tab-pane key="6" tab="学习问题"><StudyProblem /></a-tab-pane> -->
      <!-- <a-tab-pane key="7" tab="测试"><Test /></a-tab-pane> -->
      <!-- <a-tab-pane key="8" tab="训练"><Train /></a-tab-pane> -->
      <a-tab-pane key="9" tab="录音"
        ><Record :mainId="mainId" @changeActive="changeActive" :count="count"
      /></a-tab-pane>
      <a-tab-pane key="10" tab="审核">
        <div class="tab10">
          <div class="jilu">
            <div>审核记录</div>
            <!-- <div><a-button type="primary">提交审核</a-button></div> -->
          </div>
          <a-table
            :columns="columns11"
            :data-source="examineList"
            rowKey="exam_id"
          >
            <template #status="{ record }">
              {{ handleStatus(record.status) }}
            </template>
            <template #create_time="{ record }">
              {{ formatDate(record.create_time) }}
            </template>
            <template #operation="{ record }">
              <a-popconfirm
                v-if="record.status == 1"
                title="确定取消吗?"
                @confirm="handleCancel(record.exam_id)"
              >
                <a>取消</a>
              </a-popconfirm>
            </template>
          </a-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="11" tab="操作日志">
        <div class="tab11">
          <a-table :columns="columns11" :data-source="[]"></a-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="12" tab="设置"><SetUp :mainId="mainId" /></a-tab-pane>
      <a-tab-pane key="14" tab="ASR配置"><Asr :mainId="mainId" /></a-tab-pane>
    </a-tabs>
    <a-modal
      v-model:visible="addRuleVisible"
      :title="sr_id ? '编辑规则' : '添加规则'"
      class="tab4Model"
      :maskClosable="false"
      @ok="handleOk"
      @cancel="formStateRest"
      centered
      cancelText="取消"
      okText="确定"
    >
      <div class="title">同时满足下列条件</div>
      <a-form>
        <a-form-item
          v-for="(domain, index) in formState.domains"
          :key="domain.key"
        >
          <a-select
            v-model:value="domain.sc_id"
            style="width: 200px"
            placeholder="请选择"
            @change="handleChange(index, domain.sc_id)"
          >
            <a-select-option
              v-for="item in list"
              :key="item.sc_id"
              :value="item.sc_id"
              :disabled="item.disabled"
              >{{ item.name }}</a-select-option
            >
          </a-select>
          <a-select
            v-if="domain.sc_id && domain.symbolArray.length !== 0"
            style="width: 110px; margin-left: 10px"
            v-model:value="domain.symbol"
          >
            <a-select-option
              v-for="item in domain.symbolArray"
              :key="item"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
          <a-select
            v-else-if="domain.sc_id"
            :disabled="domain.symbol === '='"
            style="width: 110px; margin-left: 10px"
            v-model:value="domain.symbol"
          ></a-select>
          <a-select
            v-if="domain.sc_id && domain.valueArray.length !== 0"
            style="width: 110px; margin-left: 10px"
            v-model:value="domain.value"
          >
            <a-select-option
              v-for="item in domain.valueArray"
              :key="item"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
          <a-input
            v-else-if="domain.sc_id"
            v-model:value="domain.value"
            placeholder="请输入"
            style="width: 110px; margin-left: 10px"
          ></a-input>
          <DeleteOutlined
            v-if="formState.domains.length > 1"
            class="dynamic-delete-button"
            @click="removeDomain(domain)"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" style="width: 200px" @click="addDomain">
            <PlusOutlined />
            添加条件
          </a-button>
        </a-form-item>
      </a-form>
      <div class="title">将意向等级标签设置为</div>
      <a-select
        style="width: 200px"
        v-model:value="formState.levelValue"
        placeholder="请选择"
      >
        <a-select-option
          :value="item.lab_id"
          v-for="item in label_list"
          :key="item.lab_id"
          >{{ item.name }}</a-select-option
        >
      </a-select>
    </a-modal>
    <a-modal
      v-model:visible="hotVisible"
      title="修改热词权重"
      centered
      @ok="handleOkHot"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
    >
      <a-form>
        <a-form-item label="热词权重">
          <a-input-number v-model:value="hotVisibleValue" :min="-5" :max="5" />
        </a-form-item>
      </a-form>
      <p>
        热词权重范围[-5
        ~5]，可根据实际通话对单个热词进行权重调整，对识别不准确的词汇，应适当调高权重，对于容易识别的词，可以增加负权重
      </p>
    </a-modal>
  </div>
</template>

<script>
// import { ArrowLeftOutlined } from "@ant-design/icons-vue";
import { onMounted, reactive, ref, toRefs, watch } from "vue";
// import Train from "./Train.vue";
// import StudyProblem from "./StudyProblem.vue";
import SetUp from "./SetUp.vue";
// import Test from "./Test.vue";
import Record from "./Record.vue";
import Main from "./Main.vue";
import KnowledgeBase from "./KnowledgeBase.vue";
import Duolun from "./Duolun.vue";
import Asr from "./Asr.vue";
import GlobalConfiguration from "./GlobalConfiguration.vue";
import { useRoute, useRouter } from "vue-router";
import {
  DeleteOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons-vue";
import { getSpeechList } from "../../service/SpeechcraftMark.js";
import { formatDate, handleStatus } from "../../utils/function.js";
import {
  getHostList,
  getHotEdit,
  // postHotAdd,
  // postHotWeight,
  getRuleList,
  getConditionList,
  postRuleAdd,
  postRuleDel,
  postRuleEdit,
  getExamineList,
  postCancel,
  postTranSave,
} from "../../service/mark.js";
import { message } from "ant-design-vue";
import Sortable from "sortablejs";
const columns = [
  {
    title: "判断顺序",
    dataIndex: "index",
    width: "100px",
  },
  {
    title: "规则",
    dataIndex: "name",
    width: "65%",
    ellipsis: true,
  },
  {
    title: "客户意向标签",
    dataIndex: "lab_id",
    slots: {
      customRender: "lab_id",
    },
  },
  {
    title: "匹配次数",
    dataIndex: "count",
    width: "100px",
  },
  {
    title: "操作",
    fixed: "right",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
];
const columns11 = [
  {
    title: "话术ID",
    dataIndex: "talk_temp_id",
    key: "talk_temp_id",
  },
  {
    title: "话术名称",
    dataIndex: "talk_temp_name",
    key: "talk_temp_name",
  },
  {
    title: "提交时间",
    dataIndex: "create_time",
    key: "create_time",
    slots: {
      customRender: "create_time",
    },
  },
  {
    title: "审核状态",
    dataIndex: "status",
    key: "status",
    slots: {
      customRender: "status",
    },
  },
  {
    title: "操作",
    fixed: "right",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
  // {
  //   title: "日志详情",
  //   dataIndex: "detail",
  //   key: "detail",
  // },
];

const columnsCidian = [
  {
    title: "词典名",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "录音进度",
    dataIndex: "age",
    key: "age",
    width: "12%",
  },
  {
    title: "创建人",
    dataIndex: "address",
    width: "12%",
    key: "address",
  },
];
export default {
  name: "Mark",
  components: {
    ArrowLeftOutlined,
    DeleteOutlined,
    PlusOutlined,
    // Train,
    // StudyProblem,
    SetUp,
    // Test,
    Record,
    Main,
    KnowledgeBase,
    Duolun,
    Asr,
    GlobalConfiguration, //全局配置
  },
  setup() {
    const state = reactive({
      activeKey: "1",
      // hotList: [],
      mainId: undefined,
      classifyId: undefined,
      addRuleVisible: false,
      list: [],
      // hotValue: undefined,
      // hotEditdsds: true,
      hotVisible: false,
      hotVisibleValue: undefined,
      hw_id: undefined,
      // textareaVal: "",
      ruleList: [],
      label_list: [],
      sr_id: undefined,
      options: [],
      examineList: [],
      count: 0,
    });
    const formRef = ref();
    const formState = reactive({
      domains: [],
      levelValue: undefined,
    });
    const route = useRoute();
    const router = useRouter();
    state.mainId = route.query.id;
    state.classifyId = route.query.classify_id;
    state.hotValue = route.query.weight;

    const routerBack = () => {
      router.back();
    };
    //意向标签弹框
    const handleEdit = (record) => {
      // console.log(record);
      state.addRuleVisible = true;
      record.conditionRule.forEach((item, index) => {
        let obj = {
          sc_id: item.sc_id,
          key: Date.now() + index,
          symbol: item.symbol,
          value: item.value,
        };
        let sc_idIndex = state.list.find((item2) => item2.sc_id == item.sc_id);
        // console.log(state.list)
        sc_idIndex.disabled = true;
        if (sc_idIndex.symbol.includes(",")) {
          obj.symbolArray = sc_idIndex.symbol.split(",");
        } else {
          obj.symbolArray = [];
        }
        if (sc_idIndex.value.includes(",")) {
          obj.valueArray = sc_idIndex.value.split(",");
        } else {
          obj.valueArray = [];
        }
        formState.domains.push(obj);
        state.sr_id = record.sr_id;
        formState.levelValue = record.lab_id;
      });
    };
    const handleOk = async () => {
      console.log(formState.domains);
      if (formState.domains.length === 0) {
        message.warning('请添加条件')
        return
      }
     for (let i = 0; i <formState.domains.length; i++) {
       if(!formState.domains[i].value||!formState.domains[i].value.trim()){
         message.warning('值不能为空')
         return
       }else if(formState.domains[i].sc_id === undefined){
        message.warning('条件不能为空')
        return
      }
     }
      const arr = formState.domains.map((item) => {
        // delete item.key;
        // item.valueArray ? delete item.valueArray : "";
        // item.symbolArray ? delete item.symbolArray : "";
        return {
          sc_id: item.sc_id,
          symbol: item.symbol,
          value: item.value,
        };
      });
      if (state.sr_id) {
        const res = await postRuleEdit({
          access_token: sessionStorage.getItem("token"),
          talk_temp_id: state.mainId,
          condition: JSON.stringify(arr),
          lab_id: formState.levelValue,
          sr_id: state.sr_id,
        });
        console.log(res);
        if (res.data.status === 100000) {
          message.success(res.data.msg);
          state.addRuleVisible = false;
          state.sr_id = undefined;
          formStateRest();
          init();
        } else {
          message.error(res.data.msg);
        }
      } else {
        const res = await postRuleAdd({
          access_token: sessionStorage.getItem("token"),
          talk_temp_id: state.mainId,
          condition: JSON.stringify(arr),
          lab_id: formState.levelValue,
        });
        console.log(res);
        if (res.data.status === 100000) {
          message.success(res.data.msg);
          state.addRuleVisible = false;
          formStateRest();
          init();
        } else {
          message.error(res.data.msg);
        }
      }
    };
    const formStateRest = () => {
      formState.domains = [];
      formState.levelValue = undefined;
      state.list.forEach((item) => (item.disabled = false));
    };
    const removeDomain = (item) => {
      let index = formState.domains.indexOf(item);
      if (index !== -1) {
        formState.domains.splice(index, 1);
      }
      let index2 = state.list.findIndex((item2) => item2.sc_id === item.sc_id);
      if (index2 !== -1) {
        state.list[index2].disabled = false;
      }
    };
    const handleChange = (index, sc_id) => {
      // console.log(formState.domains);
      let arr = [];
      formState.domains.forEach((item) => {
        arr.push(item.sc_id);
      });
      let sc_idIndex = state.list.find((item) => item.sc_id == sc_id);
      if (sc_idIndex.symbol.includes(",")) {
        formState.domains[index].symbolArray = sc_idIndex.symbol.split(",");
        formState.domains[index].symbol = sc_idIndex.symbol.split(",")[0];
      } else {
        formState.domains[index].symbol = sc_idIndex.symbol;
        formState.domains[index].symbolArray = [];
      }
      if (sc_idIndex.value.includes(",")) {
        formState.domains[index].value = sc_idIndex.value.split(",")[0];
        formState.domains[index].valueArray = sc_idIndex.value.split(",");
      } else {
        formState.domains[index].value = sc_idIndex.value;
        formState.domains[index].valueArray = [];
      }
      // formState.domains[index].value = sc_idIndex.value.split(",")[0];
      // formState.domains[index].valueArray = sc_idIndex.value.split(",");
      state.list.forEach((item) => {
        if (arr.includes(item.sc_id)) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }
      });
    };
    const addDomain = () => {
      formState.domains.push({
        sc_id: undefined,
        key: Date.now(),
        symbol: ">=",
        value: "1",
      });
    };
    // const hotEdit = () => {
    //   state.hotEditdsds = false;
    //   state.hotList.forEach((item) => (state.textareaVal += item.name + ","));
    // };
    // const saveHot = async () => {
    //   if (state.textareaVal.charAt(state.textareaVal.length - 1) == ",") {
    //     state.textareaVal = state.textareaVal.substring(
    //       0,
    //       state.textareaVal.length - 1
    //     );
    //   }
    //   const res = await postHotAdd({
    //     access_token: sessionStorage.getItem("token"),
    //     name: state.textareaVal,
    //     talk_temp_id: state.mainId,
    //   });
    //   console.log(res);
    //   if (res.data.status === 100000) {
    //     message.success(res.data.msg);
    //     // state.hotVisible = false;
    //     state.hotEditdsds = true;
    //     state.textareaVal = "";
    //     init();
    //   } else {
    //     message.error(res.data.msg);
    //   }
    // };
    onMounted(() => {
      init();
      getConditionListFun();
      getSpeechListFun();
      // initSortable(); //意向标签拖动
      // let dom = document.getElementById("input-title");
      // dom.addEventListener("onmouseover", () => {
      //   console.log("sds");
      // });
    });
    watch(
      () => state.activeKey,
      (newVal) => {
        if (newVal === "4") {
          // console.log(document);
          setTimeout(() => {
            let mytb = document.querySelector("#sssssssssssss tbody");
            // console.log(mytb);
            new Sortable(mytb, {
              handle: ".ant-table-row",
              animation: 150,
              ghostClass: "blue-background-class",
              sort: true,
              async onEnd({ newIndex, oldIndex }) {
                console.log(newIndex, oldIndex);
                const source = state.ruleList[oldIndex]; // 谁
                const destination = state.ruleList[newIndex]; // 移动到哪儿
                // 拖动后同步至stata
                state.ruleList[newIndex] = source;
                state.ruleList[oldIndex] = destination;
                // console.log(state.ruleList);
                // message.success("排序完成");
              },
            });
          }, 1000);

          // new Sortable(mytb, {
          //   handle: ".ant-table-row",
          //   animation: 150,
          //   ghostClass: "blue-background-class",
          //   sort: true,
          //   async onEnd({ newIndex, oldIndex }) {
          //     console.log(newIndex, oldIndex);
          //     const source = state.ruleList[oldIndex]; // 谁
          //     const destination = state.ruleList[newIndex]; // 移动到哪儿
          //     // 拖动后同步至stata
          //     state.ruleList[newIndex] = source;
          //     state.ruleList[oldIndex] = destination;
          //     message.success("排序完成");
          //   },
          // });
        } else if (newVal === "9") {
          state.count++;
        }
      }
    );
    // const initSortable = () => {
    //   console.log(document);
    //   const mytb = document.getElementById("mytb");
    //   console.log(mytb);
    //   console.log(state.ruleList);
    //   new Sortable(mytb, {
    //     handle: ".ant-table-row",
    //     animation: 150,
    //     ghostClass: "blue-background-class",
    //     sort: true,
    //     async onEnd({ newIndex, oldIndex }) {
    //       console.log(newIndex, oldIndex);
    //       const source = state.ruleList[oldIndex]; // 谁
    //       const destination = state.ruleList[newIndex]; // 移动到哪儿
    //       // 拖动后同步至stata
    //       state.ruleList[newIndex] = source;
    //       state.ruleList[oldIndex] = destination;
    //       message.success("排序完成");
    //     },
    //   });
    // };
    const getSpeechListFun = async () => {
      const res = await getSpeechList({
        access_token: sessionStorage.getItem("token"),
        distribution_id: "0",
      });
      console.log(res);
      state.options = res.data.data.list;
    };
    const init = async () => {
      const res = await getHostList({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: state.mainId,
      });
      // console.log(res);
      state.hotList = res.data.data;
      const res2 = await getRuleList({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: state.mainId,
      });
      // console.log(res2);
      state.ruleList = res2.data.data.rule_list;
      state.label_list = res2.data.data.label_list;
      // console.log(label_list)
      state.ruleList.forEach((item, index) => {
        // console.log(item);
        state.ruleList[index].index = index + 1;
      });
      //获取审核数据
      getExamineListFun();
    };
    const handleOkHot = async () => {
      const res = await getHotEdit({
        access_token: sessionStorage.getItem("token"),
        sort: state.hotVisibleValue,
        hw_id: state.hw_id,
      });
      console.log(res);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        state.hotVisible = false;
        init();
      } else {
        message.error(res.data.msg);
      }
    };
    // const hotVisbEdit = (item) => {
    //   // console.log(item);
    //   state.hotVisible = true;
    //   state.hw_id = item.hw_id;
    //   state.hotVisibleValue = item.sort;
    // };
    // const quxiao = () => {
    //   state.textareaVal = "";
    //   state.hotEditdsds = true;
    // };
    // const handleWeight = async () => {
    //   const res = await postHotWeight({
    //     access_token: sessionStorage.getItem("token"),
    //     talk_temp_id: state.mainId,
    //     weight: state.hotValue,
    //   });
    //   if (res.data.status === 100000) {
    //     message.success(res.data.msg);
    //   } else {
    //     message.error(res.data.msg);
    //   }
    // };
    //处理客户意向标签函数
    const handleYixiang = (arr, num) => {
      arr = arr.filter((item) => item.lab_id == num);
      return arr[0].name;
    };
    //添加规则
    const addRule = () => {
      state.addRuleVisible = true;
    };
    const getConditionListFun = async () => {
      const res = await getConditionList({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: state.mainId,
      });
      console.log(res);
      state.list = res.data.data;
      state.list.forEach((item) => (item.disabled = false));
    };
    const onDelete = async (sr_id) => {
      // console.log(sr_id);
      const res = await postRuleDel({
        access_token: sessionStorage.getItem("token"),
        sr_id,
      });
      // console.log(res);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        init();
      } else {
        message.error(res.data.msg);
      }
    };
    const getExamineListFun = async () => {
      const res = await getExamineList({
        access_token: sessionStorage.getItem("token"),
        speech_id: state.mainId,
        page: 1,
      });
      // console.log(res); 
      state.examineList = res.data.data.list;
    };
    const handleCancel = async (exam_id) => {
      console.log("取消提交的审核");
      const res = await postCancel({
        access_token: sessionStorage.getItem("token"),
        exam_id,
      });
      // console.log(res);
      if (res.data.status === 100000) {
        message.success("取消成功");
        getExamineListFun();
      } else {
        message.error(res.data.msg);
      }
    };
    // const handleStatus = (status) => {
    //   switch (status) {
    //     case "1":
    //       return "审核中";
    //     case "2":
    //       return "审核不通过";
    //     case "3":
    //       return "审核通过";
    //     case "4":
    //       return "已取消";
    //   }
    // };
    const changeActive = () => {
      state.activeKey = "10";
      getExamineListFun();
    };
    const savePaixu = async () => {
      let arr = state.ruleList.map((item) => {
        return {
          sr_id: item.sr_id,
        };
      });
      const res = await postTranSave({
        access_token: sessionStorage.getItem("token"),
        tran: JSON.stringify(arr),
      });
      console.log(res);
      if (res.data.status == 100000) {
        message.success(res.data.msg);
      } else {
        message.error(res.data.msg);
      }
    };
    return {
      // activeKey: ref("10"),
      ...toRefs(state),
      columns,
      columnsCidian,
      columns11,
      routerBack,
      handleEdit,
      handleOk,
      formRef,
      formState,
      removeDomain,
      addDomain,
      handleChange,
      // hotEdit,
      // saveHot,
      handleOkHot,
      // hotVisbEdit,
      // quxiao,
      // handleWeight,
      handleYixiang,
      addRule,
      onDelete,
      formStateRest,
      init,
      handleCancel,
      handleStatus,
      changeActive,
      formatDate,
      savePaixu,
    };
  },
};
</script>

<style>
.Mark .ant-tabs-content {
  height: calc(100% - 61px);
}
.Mark .ant-tabs-tab {
  margin: 0;
}
</style>
<style scoped>
.Mark {
  height: 100%;
  background-color: #fff;
  padding-left: 10px;
  /* padding-bottom: 15px; */
}
.mark-top {
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 10px;
  /* margin: 10px 0; */
  border-bottom: 1px solid #f7f7f7;
}
.anticon-arrow-left {
  font-size: 19px;
  font-weight: bolder;
  margin-right: 20px;
  cursor: pointer;
}
.tab1,
.tab2,
.tab3 {
  padding: 0 10px 10px 10px;
  display: flex;
  width: 100%;
  height: 100%;
}
.tab5,
.tab10,
.tab11 {
  padding: 0 10px 10px 10px;
  width: 100%;
  height: 100%;
}
.ant-tabs {
  height: calc(100% - 52px);
}

.tab1left,
.tab2left {
  width: 140px;
  border-right: 1px solid #ccc;
  height: 100%;
  padding-right: 10px;
}

.tab1content,
.tab2content {
  width: calc(100% - 140px);
}
.tab1contenttop,
.tab2contenttop,
.tab4contenttop {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  width: 100%;
}
.tab2contenttop,
.tab4contenttop {
  margin-bottom: 15px;
}
.tab5contenttop {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.tab4 .ant-table-wrapper {
  margin: 0 10px;
}
.tab2 .ant-table-wrapper {
  margin-left: 10px;
}

.tab3 {
  display: flex;
  flex-direction: column;
}
.tab3top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.tab3content {
  background-color: #f7f7f7;
  height: calc(100% - 42px);
  margin-top: 10px;
  padding: 15px;
}
.father {
  display: flex;
  flex-wrap: wrap;
}
.tab3contentitem {
  background-color: #fff;
  height: 35px;
  line-height: 35px;
  margin-right: 12px;
  padding: 0 6px;
  border-radius: 4px;
  font-size: 17px;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-bottom: 8px;
}
.tab3contentitem:hover {
  background-color: #f7f7f7;
}
.jilu {
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ant-table-row .ant-btn-primary {
  margin-right: 4px;
}
.anticon-delete {
  color: rgb(47, 130, 248);
  margin-left: 10px;
}
.tab4Model .ant-form-item {
  margin-bottom: 12px;
}
.tab4Model .ant-form {
  border-bottom: 1px solid #c4bfbf;
  margin-bottom: 15px;
  padding-bottom: 10px;
}
.title {
  margin-bottom: 12px;
}
.tab3right {
  display: flex;
  align-items: center;
}
.tab4 {
  height: 100%;
  overflow: auto;
  padding-bottom: 20px;
}
</style>