<template>
  <div class="Asr">
    <a-form class="asr-form" :model="formState">
      <a-form-item label="打断">
        <!-- <a-input
          placeholder="请输入"
          v-model:value="formState.interrupt"
          style="width: 250px"
        /> -->
        <a-select
          style="width: 250px"
          v-model:value="formState.interrupt"
          placeholder="请选择"
        >
          <a-select-option :value="0">关键词打断</a-select-option>
          <a-select-option :value="1">检测到声音打断</a-select-option>
          <a-select-option :value="2">识别到文字打断</a-select-option>
          <a-select-option :value="3">说话时结束打断</a-select-option>
          <a-select-option :value="16"
            >等待时识别到一句话(非关键词)就停止</a-select-option
          >
          <a-select-option :value="32">方音时有识别到一句话</a-select-option>
          <a-select-option :value="64"
            >方音时检测到声音就暂停方音</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="VAD">
        <a-select
          style="width: 250px"
          v-model:value="formState.vad_value"
          placeholder="请选择"
        >
          <a-select-option value="0">本地VAD检测说话停止</a-select-option>
          <a-select-option value="2">ASR检测说话停止</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="滤噪">
        <!-- <a-input
          placeholder="请输入"
          v-model:value="formState.noise"
          style="width: 250px"
        /> -->
        <a-select
          style="width: 250px"
          v-model:value="formState.noise"
          placeholder="请选择"
        >
          <a-select-option value="0.1">0.1</a-select-option>
          <a-select-option value="0.2">0.2</a-select-option>
          <a-select-option value="0.3">0.3</a-select-option>
          <a-select-option value="0.4">0.4</a-select-option>
          <a-select-option value="0.5">0.5</a-select-option>
          <a-select-option value="0.6">0.6</a-select-option>
          <a-select-option value="0.7">0.7</a-select-option>
          <a-select-option value="0.8">0.8</a-select-option>
          <a-select-option value="0.9">0.9</a-select-option>
        </a-select>
      </a-form-item>
      <!-- <a-form-item label="地址">
        <a-input
          placeholder="请输入"
          v-model:value="formState.update_time"
          style="width: 250px"
        />
      </a-form-item> -->
      <a-form-item label="开始">
        <a-input
          placeholder="请输入"
          v-model:value="formState.start"
          style="width: 250px"
        />
      </a-form-item>
      <a-form-item label="停止">
        <a-input
          placeholder="请输入"
          v-model:value="formState.end"
          style="width: 250px"
        />
      </a-form-item>
      <a-form-item label="等待">
        <a-input
          placeholder="请输入"
          v-model:value="formState.waiting"
          style="width: 250px"
        />
      </a-form-item>
      <a-form-item label="最大">
        <a-input
          placeholder="请输入"
          v-model:value="formState.max"
          style="width: 250px"
        />
      </a-form-item>
      <a-form-item label="录音">
        <a-select
          style="width: 250px"
          v-model:value="formState.recording"
          placeholder="请选择"
        >
          <a-select-option value="0">不录音</a-select-option>
          <a-select-option value="1">检测到声音开始录音</a-select-option>
          <a-select-option value="2">全部录音</a-select-option>
        </a-select>
      </a-form-item>
      <!-- <a-form-item label="路径">
        <a-input
          placeholder="请输入"
          v-model:value="formState.path"
          style="width: 250px"
        />
      </a-form-item> -->
      <a-form-item label="格式">
        <a-select style="width: 250px" v-model:value="formState.format">
          <a-select-option value="wav">wav</a-select-option>
          <a-select-option value="mp3">mp3</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <div style="margin-left: 70px">
      <a-button type="primary" @click="save">保存</a-button>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "@vue/runtime-core";
import { getAsrList, postAsrSave } from "../../service/mark.js";
import { message } from "ant-design-vue";
export default {
  name: "Asr",
  props: ["mainId"],
  setup(props) {
    onMounted(() => {
      init();
    });
    const formState = reactive({
      end: 800,
      format: "wav",
      interrupt: 1,
      max: 15000,
      noise: 0.2,
      // path: undefined,
      recording: 2,
      start: 120,
      vad_value: undefined,
      waiting: 3000,
    });
    const init = async () => {
      const res = await getAsrList({
        access_token: sessionStorage.getItem("token"),
        speech_id: props.mainId,
      });
      console.log(res);
      let obj = res.data.data;
      for (let key in obj) {
        formState[key] = obj[key];
      }
    };
    const save = async () => {
      console.log("保存");
      const res = await postAsrSave({
        access_token: sessionStorage.getItem("token"),
        ...formState,
        speech_id: props.mainId,
      });
      console.log(res);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        init();
      } else {
        message.error(res.data.msg);
      }
    };
    return { formState, save };
  },
};
</script>
<style>
.Asr .ant-form-item-label {
  min-width: 70px;
}
</style>
<style  scoped>
.Asr {
  padding: 0 10px 10px 10px;
  width: 100%;
  height: 100%;
}
.asr-form {
  width: 800px;
  display: flex;
  flex-wrap: wrap;
}
.asr-form .ant-form-item {
  margin-right: 30px;
}
</style>