import http from "../axios/http"

//获取知识库分组列表
export let getTechList = (params) => {
    return http.get("/tech/tech_list", { params })
}
//添加话术知识库分组
export let getTechAdd = (params) => {
    return http.post("tech/tech_add", params)
}
//知识库分组编辑操作
export let getTechEdit = (params) => {
    return http.post("/tech/tech_edit", params)
}
//删除知识库分组操作
export let getTechDelete = (params) => {
    return http.post("/tech/tech_del", params)
}
//获取知识库列表
export let getLogyList = (params) => {
    return http.get("/logy/logy_list", { params })
}
//添加知识库
export let getLogyAdd = (params) => {
    return http.post("/logy/logy_add", params)
}
//添加知识库/多伦会话接口
export let getLogyAdd2 = (params) => {
    return http.get("/logy/logy_add", { params })
}
//添加主流程、多伦会话
export let postFlowAdd = (params) => {
    return http.post("/flow_mul/flow_add", params)
}
//获取住流程列表
export let getFlowList = (params) => {
    return http.get("/flow/flow_list", { params })
}
//删除知识库操作
export let postLogyDel = (params) => {
    return http.post("/logy/logy_del", params)
}
//知识库编辑、、获取数据接口
export let getLogyEdit = (params) => {
    return http.get("/logy/logy_edit", { params })
}
//知识库编辑、、获取数据接口
export let postLogyEdit = (params) => {
    return http.post("/logy/logy_edit", params)
}
//知识库导入//上传excel
export let postLogyImport = (params) => {
    return http({
        headers: {
            // 'Accept': 'application/json',
            // 'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        method: "POST",
        url: '/logy/logy_import',
        data: params,
    })
}
