import http from "../axios/http"

//获取主流程列表
export let getList = (params) => {
    return http.get("/flow/flow_list", { params })
}
//获取多轮会话流程列表
export let getFlowList = (params) => {
    return http.get("/flow_mul/flow_list", { params })
}
//添加主流程
export let getListAdd = (params) => {
    return http.post("/flow_mul/flow_add", params)
}
//编辑主流程操作
export let getListEdit = (params) => {
    return http.post("/flow_mul/flow_edit", params)
}
//编辑主流程操作
export let getListDelete = (params) => {
    return http.post("/flow_mul/flow_del", params)
}
//流程节点位置更换
export let getNodeChange = (params) => {
    return http.post("/flow_mul/flow_tran", params)
}
//所有节点列表
export let getAllList = (params) => {
    return http.get("/node_mul/node_list", { params })
}
//保存子节点接口
export let saveAllChild = (params) => {
    return http.post("/node_mul/node_children", params)
}
//保存连线接口
export let saveAllLine = (params) => {
    return http.post("/node_mul/node_line", params)
}
//保存所有节点
export let saveAllNode = (params) => {
    return http.post("/node_mul/node_save", params)
}
// //关键词列表
// export let getSynonymList = (params) => {
//     return http.get("/synonym/synonym_list", { params })
// }
// //关键词添加
// export let postSynonymAdd = (params) => {
//     return http.get("/synonym/synonym_add", { params })
// }
//关键词列表
export let getSynonymDetail = (params) => {
    return http.get("/node_mul/synonym_detail", { params })
}
//关键词添加(流程)
export let postSynonymAdd = (params) => {
    return http.post("/flow_mul/synonym", params)
}
//关键词列表
export let getSynonymList = (params) => {
    return http.get("/flow_mul/flow_synonym", { params })
}