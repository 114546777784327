import http from "../axios/http"

//基础设计列表
export let getList = (params) => {
    return http.get("/basic/basic_list", { params })
}
//基本设置编辑
export let getEdit = (params) => {
    return http.get("/basic/basic_edit", { params })
}
//基本设置编辑
export let postEdit = (params) => {
    return http.post("/basic/basic_edit", params)
}
//获取主流程列表
export let getFlowlist = (params) => {
    return http.get("/flow/flow_list", { params })
}
//获取多伦会话列表
export let getFlowmullist = (params) => {
    return http.get("/flow_mul/flow_list", { params })
}
//录音下载
export let getDownload = (params) => {
    return http.post("/examine/tts_content", params)
}
//上传录音
export let postUpload = (params) => {
    return http.post("/basic/upload_basic_wav", params)
}
